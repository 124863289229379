@import '../../../../../styles/abstracts/index';

td::before {
  display: none;
}

.contentAuthor {
  display: inline-flex;
  align-items: center;
  gap: spacing(3);
}

@include respond(tab-land) {
  table,
  tbody,
  tr,
  td {
    display: block;
    height: auto;
  }

  //Hides Table Header
  thead {
    display: none;
  }

  td:first-of-type {
    padding: spacing(4);
    height: auto;
  }

  .root td:nth-of-type(2),
  .root td:nth-of-type(3),
  .root td:nth-of-type(4),
  .root td:nth-of-type(5) {
    display: none;
  }
}
