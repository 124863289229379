@import '../../../styles/abstracts/index';

.root {
  height: auto;
}

.input {
  outline: none;
  border: none;

  background-color: transparent;

  font-family: $font-family-default;
  font-weight: 400;
  font-size: pxToRem(16);
  line-height: pxToRem(24);

  height: 100%;
  width: 100%;
}

.root :global(.end-adornment) {
  align-self: flex-start;
}
