@import '../../../../../styles/abstracts/index';

.root {
  display: flex;
  flex-direction: column;
}
.error {
  text-align: center;
  margin-bottom: spacing(4);
}

.input {
  margin: 0;
  margin-bottom: spacing(4);
}
