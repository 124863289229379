@import '../../../styles/abstracts/index';

.root {
  // margin: spacing(1);
}

.helperText {
  font-weight: 400;
  font-size: pxToRem(14);
  line-height: pxToRem(20);
  margin-top: spacing(2);
}

.co {
  &-basic {
    color: $color-monochrome-label;
  }
  &-error {
    color: $color-error-dark;
  }
}
