@import '../../../../../styles/abstracts/index';

.root {
}

.header {
  display: flex;
  align-items: flex-start;
}

.actions {
  display: flex;
  flex-direction: column;
}

.action {
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: flex-start;
  padding: spacing(2) 0;

  &:not(:last-child) {
    margin-bottom: spacing(3);
  }

  &Icon {
    border: 1px solid $color-primary;
    border-radius: 1000px;
    margin-right: spacing(4);
  }

  &Description {
    text-align: left;

    & :first-child {
      font-weight: 600;
    }

    & :last-child {
      color: $color-monochrome-label;
    }
  }
}

.action:hover .actionIcon,
.action:focus-within .actionIcon {
  outline: 2px solid $color-primary;
  background-color: $color-primary-bg;
}

.action:hover :first-child {
  text-decoration: underline;
  text-underline-offset: 2px;
}

.card {
  width: pxToRem(510);
}
