@import '../../../../../styles/abstracts/index';

.root {
  height: 100vh;

  display: inline-flex;
  flex-direction: column;
  justify-content: center;

  padding: spacing(4);

  box-shadow: $drop-shadow-medium;

  background-color: $color-monochrome-white;

  &:not(.isCollapsed) {
    min-width: pxToRem(224);
  }
}

.logoContainer {
  margin-bottom: spacing(12);
  height: pxToRem(38);

  svg {
    height: 100%;
    width: 100%;
  }
}

.logoContainer.isQibri svg {
  width: auto;
}

.navigation {
  flex: 1;

  display: flex;
  flex-direction: column;
  gap: 8px;
}

.actions {
  display: flex;
  justify-content: center;
  align-items: center;
}

// MODIFIERS

.isCollapsed {
}

// MEDIA QUERIES

@include respond(tab-land) {
  .root {
    padding: 0;
    height: fit-content;
    box-shadow: 1px 2px 20px rgba(234, 234, 234, 0.6);
  }

  .logoContainer {
    display: none;
  }

  .actions {
    display: none;
  }

  .navigation {
    flex-direction: row;
    justify-content: space-evenly;
  }
}
