@import '../../../styles/abstracts/index';

.root {
  display: flex;
}

.or-horizontal {
  flex-direction: row;
  align-items: flex-start;
}
.or-vertical {
  flex-direction: column;
}
