@import '../../../styles/abstracts/index';

.root {
  padding: spacing(6);
  background-color: $color-monochrome-white;
  border: 1px solid $color-gray-200;
  @include borderRadius(2);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  transition: all 0.2s;

  & > :not(:last-child) {
    margin-bottom: spacing(2);
  }

  &:focus {
    border-color: $color-primary;
    outline: $color-primary-light solid $outline-width-sm;
  }
}

.input {
  display: none;
}

.actionMessage {
  display: flex;
}

// MODIFIERS

.isActive {
  background-color: $color-gray-200;
  border-color: $color-primary;
}
