@import '../../../styles/abstracts/index';

.root {
  border-collapse: collapse;

  &.isFullWidth {
    width: 100%;
  }
}

.head {
}

.body {
}

.cell {
  padding: spacing(1) spacing(6);
  height: 64px;

  font-size: pxToRem(14);
  line-height: pxToRem(20);
  color: $color-monochrome-label;

  &.activeSortableCell {
    color: $color-monochrome-ash;
    font-weight: 500;
  }

  &.isHeadCell {
    text-align: left;
    font-weight: 400;
    height: 56px;
  }

  &.clickableCell {
    cursor: pointer;

    &:hover {
      background-color: $color-monochrome-background;
    }
  }

  &.isLeadCell {
    color: $color-monochrome-ash;
    position: relative;
  }

  &.isLeadCell a {
    position: absolute;
    inset: 0;
  }

  &:not(.activeSortableCell) {
    .sortArrow {
      visibility: hidden;
    }

    &:hover {
      .sortArrow {
        visibility: visible;
      }
    }
  }

  &.sortableCell {
    .sortArrow {
      display: flex;
      align-items: center;

      svg {
        height: 20px;
        width: 20px;
      }
    }
  }
}

.cellText {
  display: flex;
  align-items: center;
  gap: spacing(1);
}

td:first-child .cellText {
  gap: spacing(3);
}

.row {
  border-bottom: 1px solid $color-monochrome-line;

  &:not(.isHeadRow) {
    &:last-child {
      border-bottom: none;
    }
  }

  &.isClickableRow:has(a) {
    &:hover,
    &:focus-within {
      background-color: $color-monochrome-background;
    }

    &:hover td:nth-of-type(1) div[class*='Avatar'],
    &:focus-within td:nth-of-type(1) div[class*='Avatar'] {
      border: 1pt solid $color-primary;
    }
  }
}
