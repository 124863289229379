@import '../../../../../styles/abstracts/index';

.ic {
  flex-shrink: 0;

  &-practice {
    background-color: $color-primary-bg;
    color: $color-primary;
  }

  &-document {
    background-color: $color-tertiary-bg;
    color: $color-tertiary-dark;
  }

  &-annex {
    background-color: $color-secondary-bg;
    color: $color-secondary;
  }

  &-search {
    background-color: $color-primary-bg;
    color: $color-primary;
  }

  &-recent_search {
    background-color: $color-primary-bg;
    color: $color-primary;
  }

  &-assignee {
    background-color: $color-primary-bg;
    color: $color-primary;
  }

  &-taxonomy {
    background-color: $color-primary-bg;
    color: $color-primary;
  }

  &-approval {
    background-color: $color-tertiary-bg;
    color: $color-tertiary-dark;
  }

  &-template {
    background-color: $color-secondary-bg;
    color: $color-secondary;
  }

  &-approval_declined {
    background-color: $color-accent-bg;
    color: $color-accent-dark;
  }
}
