@import '../../../../../../styles/abstracts/index';

.root {
  margin-top: spacing(4);
}

.reviewers {
  margin-top: spacing(3);
  display: flex;
  flex-direction: column;
}

.importReviewersBtn {
  align-self: flex-end;
}

.reviewerDisplay {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: spacing(2);
  }
}

.reviewerInfo {
  display: flex;
  align-items: center;
}

.reviewerAvatar {
  margin-right: spacing(2);
}

.footer {
  display: flex;
  justify-content: flex-end;

  margin-top: spacing(8);
}
