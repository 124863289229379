@import '../../../../../styles/abstracts/index';

.card {
  max-width: pxToRem(680);
  width: 100vm;
}

.content {
  margin-top: spacing(4);
}

.actions {
  margin-top: spacing(7);
  display: flex;
  justify-content: flex-end;
  gap: spacing(4);
}

.roundIcon {
  @include borderRadius(10000);
}

.paragraph {
  margin-top: spacing(2);
}

.image {
  max-width: 100%;
  margin-top: spacing(4);
  border: 1px solid $color-monochrome-line;
  @include borderRadius(4);
}

@include respond(tab-land) {
  .modalRoot {
    align-items: flex-start;
  }

  .modal {
    max-width: unset;
    max-height: unset;
  }

  .card {
    width: 100vm;
    border-radius: 0;
  }
  .cardBody {
    padding: spacing(4) spacing(7);
  }

  .actions {
    button {
      width: 45%;
    }
  }
}
