@import '../../../../../styles/abstracts/index';

.name {
  display: flex;
  align-items: center;

  & > :first-child {
    margin-right: spacing(2);
  }
}

.contentAuthor {
  display: inline-flex;
  align-items: center;
  gap: spacing(3);
}

.actionCell {
  text-align: right;
  justify-content: flex-end;
}

.fakeButton {
  text-decoration: underline;
  border: none;
  background-color: transparent !important;
}

.fakeButton:hover {
  background-color: transparent;
}


@include respond(tab-land) {
  .root td:nth-of-type(2),
  .root td:nth-of-type(3),
  .root td:nth-of-type(4),
  .root td:nth-of-type(5) {
    display: none;
  }
}
