@import '../../../../../styles/abstracts/index';

.root {
  max-height: pxToRem(300);
  overflow-y: auto;
  position: relative;
}

.input {
  position: sticky;
  top: 0;
  z-index: 1;
}

.userList {
  margin-top: spacing(8);
  margin-bottom: spacing(8);
}

.userListItem {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: spacing(8);
  }
}

.userInfo {
  flex: 1;
  display: flex;
  align-items: center;

  & > *:not(:last-child) {
    margin-right: spacing(4);
  }
}

.loading {
  margin-top: spacing(8);
  margin-bottom: spacing(8);
}

.noData {
  display: flex;
  align-items: center;
  justify-content: center;

  & > *:first-child {
    margin-right: spacing(2);
  }
}
