@import '../../../../../styles/abstracts/index';

.page {
  height: calc(100% - pxToRem(74));
  overflow-y: auto;
}

.commentForm {
  margin-bottom: spacing(8);
}

.pageActions {
  display: flex;
  justify-content: flex-end;
  margin-bottom: spacing(8);
  flex-basis: pxToRem(42);

  :not(:first-child) {
    margin-left: spacing(2);
  }
}

.header {
  display: none;
}

@include respond(tab-port) {
  .pageActions {
    display: none;
  }

  .page {
    display: flex;
    flex-direction: column;
    padding: spacing(6);
  }

  .header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: spacing(2.5) spacing(2);

    box-shadow: $drop-shadow-small;
    position: relative;

    .title {
      text-align: center;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .commentForm {
    margin: 0;

    z-index: 1;

    padding: spacing(6);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: $color-monochrome-white;
    box-shadow: 0px -4px 6px rgba(0, 0, 0, 0.04);
  }
}
