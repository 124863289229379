@import '../../../../../styles/abstracts/index';

.root {
  height: 100vh;
  width: 100vw;

  display: flex;
  justify-content: center;
  align-items: center;

  background: $color-monochrome-background;
}

.cardBody {
  width: pxToRem(606);
  padding: spacing(12);

  display: flex;
  flex-direction: column;

  justify-content: center;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: spacing(4);

  margin-bottom: spacing(6);
}

.link {
  color: $color-primary;
}

.logo {
  height: pxToRem(75);
}

.footer {
  margin-top: spacing(6);
  text-align: center;
}

.fakeLink {
  color: $color-primary !important;
  text-decoration: underline;
  text-underline-offset: 4px;
  font-size: pxToRem(14);
  line-height: pxToRem(20);
  cursor: pointer;
}

@include respond(tab-port) {
  .card {
    border-radius: 0;
  }

  .cardBody {
    height: 100vh;
    width: 100vw;

    padding: spacing(6);
    padding-top: 15vh;

    justify-content: flex-start;
  }

  .header {
    margin-bottom: spacing(8);
  }

  .header h1 {
    text-align: center;
  }

  .logo {
    height: pxToRem(54);
  }
}
