@import '../../../../../styles/abstracts/index';

.section {
  border: none;
  margin-bottom: spacing(12);
}

.row {
  margin-bottom: spacing(6);

  display: flex;
  align-items: flex-start;
  justify-content: stretch;
  gap: spacing(6);

  & > * {
    flex: 1;
  }
}

.languageBar {
  margin-top: spacing(12);
  margin-bottom: spacing(8);
}

.applyMetadata .body {
  display: flex;

  .content {
    flex: 1;
  }
  .button {
    background-color: transparent;
  }
}

.formBody {
  display: none;

  &.active {
    display: block;
  }
}

.userRow {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.userInfo {
  margin-right: spacing(2);
}

.list {
  list-style: none;

  li {
    margin-bottom: spacing(4);
    display: flex;
    gap: spacing(4);

    & > div {
      flex: 1;
    }

    & > button {
      width: auto;
    }
  }
}
