@import '../../../../../styles/abstracts/index';

.root {
  z-index: 1001;
  background-color: $color-monochrome-white;

  position: fixed;
  height: 100vh;
  width: 100vw;
  overflow-y: auto;

  top: 0;
  left: 0;

  display: grid;
  grid-template-columns: minmax(pxToRem(150), 1fr) pxToRem(824) minmax(
      pxToRem(150),
      1fr
    );
}

.main {
  margin-top: spacing(24);
}

.title {
  margin-bottom: spacing(6);
}

.body {
  .fileName {
    margin-bottom: spacing(6);
  }
}

.closeBtn {
  position: sticky;
  top: spacing(5);
  left: spacing(6);
}

.footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: spacing(6);
  padding-bottom: spacing(6);
  border-top: 1px solid $color-monochrome-placeholder;

  button:not(:last-child) {
    margin-right: spacing(2);
  }
}
