@import '../../../styles/abstracts/index';

.root {
  position: fixed;

  padding: spacing(2);
  max-height: 40vh;
  max-width: 60vw;

  overflow: hidden;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  & > :not(:last-child) {
    margin-bottom: spacing(4);
  }
  z-index: 2000;
}

.pos {
  &-bottom {
    bottom: 0;
    left: 50%;

    transform: translateX(-50%);
  }
}
