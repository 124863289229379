@import '../../../../../styles/abstracts/index';

.actions {
  display: flex;
  flex-direction: column;
  padding: spacing(8) spacing(6);

  & > *:not(:last-child) {
    margin-bottom: spacing(2);
  }

  & button {
    box-shadow: $drop-shadow-medium;
  }
}

.actionBar {
  height: calc(100dvh - 64px);
  position: sticky;
  top: 64px;
}

.sidebarMobile {
  display: none;
}

@include respond(tab-port) {
  .actionBar {
    display: none;
  }

  .sidebarMobile.hide {
    display: none;
  }

  .sidebarMobile {
    display: block;
    position: fixed;
  }

  .sheet {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    background-color: $color-monochrome-white;
  }

  .icon {
    color: $color-primary;
  }

  .link {
    display: flex;
    align-items: center;
    padding: spacing(4) spacing(6);

    &:not(:last-child) {
      border-bottom: 1px solid $color-monochrome-line;
    }
  }

  .comments {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;

    height: 100%;
    background-color: $color-monochrome-white;
  }
}
