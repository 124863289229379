@import '../../../../styles/abstracts/index';

.root {
  display: flex;
  background-color: $color-monochrome-background;
}

.navigation {
  z-index: 100;
}

.mainContainer {
  flex: 1;

  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 0 24px;
  grid-template-rows: min-content auto;

  height: 100vh;
  overflow-y: auto;
}

.header {
  position: sticky;
  top: 0;

  grid-column: 1/-1;
  z-index: 1;
}

.main {
  grid-column: 1/-1;
  margin: 0 spacing(8);
}

@include respond(desktop) {
  .main {
    grid-column: 2/12;
    margin: 0;
  }
}

@media screen and (min-width: 834px) and (max-width: 1240px) {
  .main {
    margin: 0 spacing(8);
  }
}

@include respond(tab-land) {
  .root {
    flex-direction: column-reverse;

    /**
     * fixme: dirty fix for mobile navigation placement
     *
     * could also be solved like this probably: https://sbx.webflow.io/100vh-viewport-safari-fix
     */
    margin-bottom: 72px;
    min-height: calc(100vh - 72px);
  }

  .navigation {
    flex-grow: 0;
    position: fixed;
    bottom: 0;
    width: 100vw;
  }

  .mainContainer {
    height: 100%;

    grid-template-columns: repeat(6, minmax(pxToRem(88), 1fr));
  }

  .main {
    grid-column: 1/-1;
    margin: 0 24px;
  }
}

@include respond(tab-port) {
  .mainContainer {
    gap: 0;
    grid-template-columns: repeat(6, 1fr);
  }
}

@include respond(phone) {
  .mainContainer {
    grid-template-columns: repeat(4, 1fr);
  }
}
