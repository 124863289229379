@import '../../../styles/abstracts/index';

.root {
  list-style: none;
  @include borderRadius(2);
}

.button {
  width: 100%;
  justify-content: flex-start !important;
}

.button i:not(:first-child) {
  margin-left: auto;
}

.wrapper {
  max-block-size: 0;
  overflow: hidden;
  transition: all 0.2s cubic-bezier(0, 0, 0.38, 0.9);
}

.content {
  padding: spacing(2.5) spacing(4);
  font-size: pxToRem(16);
}

.co {
  &-warning {
    color: $color-warning-dark;
    background: $color-warning-bg;
  }
}
