@import '../../../styles/abstracts/index';

.tab {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;

  padding-top: spacing(2);
  padding-bottom: spacing(2);
  cursor: pointer;
  z-index: 1;
}

.icon {
  margin-left: spacing(2);
  min-width: pxToRem(24);
}

.va-primary {
  background: none;
  color: $color-monochrome-label;

  &:hover,
  &:active {
    color: $color-primary;
    box-shadow: inset 0 -2px $color-primary;
  }

  &:focus {
    outline: 2px solid $color-primary;
  }

  &.isCurrent {
    color: $color-primary;
    box-shadow: inset 0 -2px $color-primary;
  }
}

.va-secondary {
  background: $color-monochrome-background;

  border-top: 2px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: 1px solid $color-monochrome-outline;
  border-left: 1px solid transparent;

  &:hover,
  &:active,
  &:focus {
    border-top-color: $color-primary;
    border-bottom-color: transparent;
    border-right-color: $color-monochrome-outline;
    border-left-color: $color-monochrome-outline;

    background: $color-monochrome-white;
  }

  &.isCurrent {
    border-top-color: $color-primary;
    border-right-color: $color-monochrome-outline;
    border-bottom-color: transparent;
    border-left-color: $color-monochrome-outline;

    background: $color-monochrome-white;
    color: $color-monochrome-ash;
  }

  // &:first-child {
  //   border-left-color: transparent;
  // }
  // &:last-child {
  //   border-right-color: transparent;
  // }
}

// MODIFIERS

.isFullWidth {
  flex: 1;
}
