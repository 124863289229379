@import '../../../styles/abstracts/index';

.root {
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle {
  display: inline-block;

  --border-width: 4px;

  height: pxToRem(36);
  width: pxToRem(36);

  border: var(--border-width) $color-primary-bg solid;
  border-top: var(--border-width) $color-primary solid;
  border-radius: 50%;

  animation: spin 0.5s infinite linear;
}

.sz {
  &-x-small .circle {
    --border-width: 2px;
    height: pxToRem(16);
    width: pxToRem(16);
  }
  &-small .circle {
    --border-width: 3px;
    height: pxToRem(24);
    width: pxToRem(24);
  }
  &-medium .circle {
    --border-width: 4px;
    height: pxToRem(36);
    width: pxToRem(36);
  }
  &-large .circle {
    height: pxToRem(36);
    width: pxToRem(36);
  }
  &-x-large .circle {
    --border-width: 6px;
    height: pxToRem(48);
    width: pxToRem(48);
  }
  &-2x-large .circle {
    --border-width: 6px;
    height: pxToRem(60);
    width: pxToRem(60);
  }
}

@media (prefers-reduced-motion) {
  .circle {
    animation: spin 5s infinite linear;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
