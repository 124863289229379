@import '../../../../../styles/abstracts/index';

.root {
  min-height: 100vh;
  width: 100vw;
  max-width: 100%;
  background-color: $color-monochrome-background;

  display: flex;
  flex-direction: column;
}

.main {
  width: 100vw;
  max-width: 100%;
  flex: 1;
}
