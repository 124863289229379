@import '../../../../../styles/abstracts/index';

.card {
  min-width: pxToRem(500);
  max-width: 60vw;

  max-height: 60vh;
  min-height: pxToRem(510);

  display: flex;
  flex-direction: column;
}

.cardHeader {
  display: flex;
  align-items: center;
}

.cardTitle {
  flex: 1;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cardBody {
  overflow: auto;
}

.section:not(:last-child) {
  display: grid;
  gap: spacing(6);
  margin-top: spacing(6);
  border-bottom: 1pt solid $color-monochrome-line;
}

.topic {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.cardHeaderMobile {
  display: none;
}

.contact {
  display: none;
}

@include respond(tab-port) {
  .modal {
    position: fixed;
    top: 0;
    left: 0;

    height: 100%;
    max-height: 100vh;
    width: 100%;
    max-width: 100vw;
  }

  .card {
    min-width: unset;
    max-width: unset;
    width: 100%;

    max-height: unset;
    min-height: unset;
    height: 100%;

    border-radius: 0;
  }

  .cardHeader {
    display: none;
  }

  .cardHeaderMobile {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: spacing(2.5) spacing(4);

    box-shadow: $drop-shadow-small;
    position: relative;

    .title {
      text-align: center;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .cardHeaderMobile + .cardBody {
    padding-top: spacing(6);
    padding-bottom: calc(spacing(6) + spacing(18));
  }

  .contact {
    display: flex;
    flex-direction: column;
  }

  .contactInfo {
    display: flex;
    align-items: center;
    gap: spacing(3);

    margin-bottom: spacing(6);
  }
}
