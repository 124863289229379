@import '../../../styles/abstracts/index';

.root {
  display: flex;
  align-items: center;
}

.crumb {
  display: flex;
  align-items: center;

  .icon {
    color: $color-monochrome-body;

    &.inverted {
      color: $color-monochrome-white;
    }
  }
}

@include respond(tab-port) {
  .root > :nth-child(-n + 2) {
    display: none;
  }
}
