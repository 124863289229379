@import '../../../styles/abstracts/index';

.root {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  visibility: collapse;
}

.isOpen {
  visibility: visible;
}

.co {
  &-transparent {
    background-color: transparent;
  }

  &-tinted {
    background-color: colorOverlay('dark', 0.27);
  }
}
