@import '../../../../../styles/abstracts/index';

.card {
  width: pxToRem(400);
}

.footer {
  display: flex;
  justify-content: flex-end;
  gap: spacing(2);
}

.button {
  display: block;
  background-color: $color-monochrome-white;
  border: none;
}

.heading {
  margin-top: spacing(4);
  margin-bottom: spacing(1);
}
