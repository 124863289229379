@import '../../../../../styles/abstracts/index';

@mixin displayBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.root {
  position: fixed;
  inset: 0;

  background-color: $color-monochrome-background;

  z-index: 99999;
}

/*
    base styles cover the whole page in a very high z-index overlay

    According to the break points the overlay gets displayed with the message

    eg. phone means phone view is blocked
*/

.bp-phone,
.bp-tab-port,
.bp-tab-land,
.bp-desktop {
  display: none;
}

@include respond(phone) {
  .bp-phone {
    @include displayBlock();
  }
}
@include respond(tab-port) {
  .bp-tab-port {
    @include displayBlock();
  }
}
@include respond(tab-land) {
  .bp-tab-land {
    @include displayBlock();
  }
}
@include respond(desktop) {
  .bp-desktop {
    @include displayBlock();
  }
}
