@import '../../../../../styles/abstracts/index';

.root {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: spacing(3) spacing(6);

  box-shadow: $drop-shadow-small;

  position: sticky;
  top: 0;
  z-index: 1;
  background-color: $color-monochrome-white;
}

.inverted {
  background-color: $color-primary-dark;
}

@include respond(tab-port) {
  .root {
    position: sticky;
    top: 0;

    padding: spacing(2) spacing(6);
  }
}
