@import '../../../styles/abstracts/index';

.root {
  outline: none;
  border: none;

  background-color: transparent;

  font-weight: 400;
  font-size: pxToRem(16);
  line-height: pxToRem(24);

  width: 100%;
}
