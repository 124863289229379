// 🌈 COLORS

$color-primary: var(--color-primary);
$color-primary-bg: var(--color-primary-bg);
$color-primary-dark: var(--color-primary-dark);
$color-primary-darkmode: var(--color-primary-darkmode);
$color-primary-light: var(--color-primary-light);
$color-primary-shade-1: var(--color-primary-shade-1);
$color-primary-shade-2: var(--color-primary-shade-2);

$color-secondary: var(--color-secondary);
$color-secondary-dark: var(--color-secondary-dark);
$color-secondary-light: var(--color-secondary-light);
$color-secondary-shade-1: var(--color-secondary-shade-1);
$color-secondary-bg: var(--color-secondary-bg);

$color-tertiary: var(--color-tertiary);
$color-tertiary-bg: var(--color-tertiary-bg);
$color-tertiary-dark: var(--color-tertiary-dark);
$color-tertiary-darkmode: var(--color-tertiary-darkmode);
$color-tertiary-light: var(--color-tertiary-light);

$color-accent: var(--color-accent);
$color-accent-bg: var(--color-accent-bg);
$color-accent-shade-1: var(--color-accent-shade-1);
$color-accent-shade-2: var(--color-accent-shade-2);
$color-accent-dark: var(--color-accent-dark);

$color-success: var(--color-success);
$color-success-dark: var(--color-success-dark);
$color-success-darkmode: var(--color-success-darkmode);
$color-success-light: var(--color-success-light);
$color-success-bg: var(--color-success-bg);

$color-warning: var(--color-warning);
$color-warning-light: var(--color-warning-light);
$color-warning-bg: var(--color-warning-bg);
$color-warning-dark: var(--color-warning-dark);
$color-warning-darkmode: var(--color-warning-darkmode);

$color-error: var(--color-error);
$color-error-bg: var(--color-error-bg);
$color-error-dark: var(--color-error-dark);
$color-error-darkmode: var(--color-error-darkmode);
$color-error-light: var(--color-error-light);

$color-overlay: (
  light: var(--color-rgb-overlay-light),
  dark: var(--color-rgb-overlay-dark),
);

$color-monochrome-ash: var(--color-monochrome-ash);
$color-monochrome-background: var(--color-monochrome-background);
$color-monochrome-body: var(--color-monochrome-body);
$color-monochrome-input: var(--color-monochrome-input);
$color-monochrome-label: var(--color-monochrome-label);
$color-monochrome-line: var(--color-monochrome-line);
$color-monochrome-off-black: var(--color-monochrome-off-black);
$color-monochrome-off-white: var(--color-monochrome-off-white);
$color-monochrome-placeholder: var(--color-monochrome-placeholder);
$color-monochrome-white: var(--color-monochrome-white);
$color-monochrome-black: var(--color-monochrome-black);
$color-monochrome-outline: var(--color-monochrome-outline);

$color-gray-200: var(--color-gray-200);

// 🌌 SPACING

$spacer-base-value: 4px;

// ✨ EFFECTS

$drop-shadow-small: 0px 4px 6px rgba(0, 0, 0, 0.04);
$drop-shadow-medium: 0px 12px 16px rgba(0, 0, 0, 0.04);
$drop-shadow-large: 0px 24px 56px rgba(0, 0, 0, 0.04);

$blur-default: blur(20px);

// 🅰️ TYPOGRAPHY

$font-family-default: 'AvenirNext', 'Roboto', sans-serif;
$font-color-default: $color-monochrome-ash;
$font-size-percent-default: 100; // @100%: 1rem = 16px

// ⚒️ EXTRA HELPERS

$border-radius-base: 4px;
$outline-width-sm: 2px;

$transition-duration-default: 0.2s;
