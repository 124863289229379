@import '../../../../../styles/abstracts/index';

.root {
  display: flex;
  justify-content: space-between;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: spacing(8);
}

.body {
  flex: 1;
  max-width: pxToRem(1240);

  padding: 0 spacing(8);
  margin: 0 auto;
}

.wrapper {
  display: flex;
  gap: spacing(6);
}

.errorButton {
  margin-top: spacing(6);
}

@include respond(tab-port) {
  .body {
    margin: 0;
    padding: 0 spacing(6) 72px;

    section {
      margin-top: spacing(6);
      margin-bottom: spacing(8);
    }
  }

  .title {
    display: none;
  }

  .docBody {
    padding: 0 spacing(3);
  }
}

@include respond(default) {
  .wrapper {
    flex-wrap: wrap;

    & > div {
      width: 100%;
    }
  }
}
