@import '../../../styles/abstracts/index';

.root {
  position: relative;
}

// Height of the arrow = 11.75px
// => side of square (height * 2 / sqrt(2)) ~= 16px
// => rotate 45 deg
$arrow-side: 16px;

.text {
  overflow: visible !important;
  position: absolute;
  border-radius: 8px;
  width: max-content;
  max-width: pxToRem(350) !important;
  text-align: center;
  z-index: 1;
  padding: spacing(4) spacing(4);
  margin-top: spacing(3);
  text-align: left;

  &::after {
    content: '';
    position: absolute;
    display: inline-block;
    height: $arrow-side;
    width: $arrow-side;
  }
}

.co-dark {
  background-color: $color-monochrome-body !important;
  color: $color-monochrome-off-white !important;

  &.text::after {
    background-color: $color-monochrome-body !important;
  }
}

.po-top {
  &.text::after {
    top: calc(($arrow-side / 2 - 1px) * -1);
  }

  &.ba-center {
    &.text::after {
      left: 50%;
      transform: translate(-50%) rotate(45deg);
    }
  }
}

.closeButton {
  position: absolute;
  top: spacing(1);
  right: spacing(3);
  background: transparent;
  color: $color-monochrome-off-white;
  border: none;
  outline: none;
  font-size: pxToRem(32);
  cursor: pointer;
}

.roundButton {
  @include borderRadius(5);
}

.contentBody {
  margin-top: spacing(1);
}
