@import '../../../styles/abstracts/index';

.stage {
  height: pxToRem(24);
  width: pxToRem(24);
  border-radius: 2000px;

  border: 1px solid transparent;

  display: flex;
  justify-content: center;
  align-items: center;
  justify-self: center;

  svg {
    height: pxToRem(13.33);
    width: pxToRem(13.33);
  }
}

.title {
  display: inline-block;
  line-height: 1rem;
}

.line {
  background-color: $color-monochrome-line;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.space {
  min-height: spacing(6);
}

// MODIFIERS

// status
.st {
  &-complete {
    .stage {
      background-color: $color-primary;
      border-color: $color-primary;
    }

    .line {
      background-color: $color-primary;
    }

    svg {
      stroke: $color-monochrome-off-white;
    }
  }
  &-incomplete {
    .stage {
      background-color: $color-monochrome-input;
      border-color: $color-monochrome-line;
    }
  }
  &-current {
    & .stage {
      background-color: $color-monochrome-off-white;
      border-color: $color-primary;
      outline: 4px solid $color-primary-light;
    }
  }
}

// orientation
.or-vertical {
  display: grid;
  grid-template-columns: min-content auto;
  grid-template-rows: auto auto;
  column-gap: spacing(2);

  align-items: center;

  &:last-child {
    .line {
      opacity: 0;
    }
  }

  .line {
    width: 1px;
    height: 100%;
    justify-self: center;
  }

  .content {
    margin-top: spacing(2);
    margin-bottom: spacing(4);
  }

  .subtitle {
    margin-bottom: spacing(2);
  }
}

.or-horizontal {
  display: grid;
  grid-template-columns: min-content auto auto;
  grid-template-rows: auto min-content;
  column-gap: spacing(2);

  align-items: center;

  &:not(:first-child) {
    margin-left: spacing(2);
  }

  &:last-child {
    .line {
      width: 0;
      opacity: 0;
    }
  }

  .stage {
    grid-column: 1/2;
    grid-row: 1/2;
  }

  .title {
    grid-column: 2/3;
    grid-row: 1/2;
  }

  .content {
    grid-column: 2/3;
    grid-row: 2/3;
  }

  .line {
    grid-column: 3/4;
    grid-row: 1/2;

    height: 1px;
    width: calc(pxToRem(28) - spacing(2));
    justify-self: start;
  }
}
