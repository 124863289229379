@import '../../../styles/abstracts/index';

.root {
  border: 1px solid transparent;
  cursor: pointer;
  font-weight: 500;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  transition: all 0.2s;

  &:focus {
    outline: $color-primary-light solid $outline-width-sm;
  }

  &:active {
    outline: none;
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  .btnIcon,
  .btnTrailIcon {
    height: pxToRem(22);
    width: pxToRem(22);

    svg {
      height: 100%;
      width: 100%;
    }
  }

  .btnIcon {
    margin-right: spacing(3);
  }

  .btnTrailIcon {
    margin-left: spacing(3);
  }
}

// size

.si {
  &-huge {
    font-size: pxToRem(18);
    line-height: pxToRem(28);
    padding: spacing(4.5) spacing(6);

    @include borderRadius(3);
  }

  &-large {
    font-size: pxToRem(16);
    line-height: pxToRem(24);
    padding: spacing(4) spacing(6);

    @include borderRadius(3);
  }

  &-medium {
    font-size: pxToRem(16);
    line-height: pxToRem(24);
    padding: spacing(3) spacing(5);

    @include borderRadius(2);

    .btnIcon {
      margin-right: spacing(2);
    }

    .btnTrailIcon {
      margin-left: spacing(2);
    }
  }

  &-small {
    font-size: pxToRem(14);
    line-height: pxToRem(20);
    padding: spacing(2.5) spacing(4);

    @include borderRadius(2);

    .btnIcon,
    .btnTrailIcon {
      height: pxToRem(16);
      width: pxToRem(16);
    }

    .btnIcon {
      margin-right: spacing(2);
    }

    .btnTrailIcon {
      margin-left: spacing(2);
    }
  }

  &-x-small {
    font-size: pxToRem(14);
    line-height: pxToRem(20);
    padding: spacing(2) spacing(3.5);

    @include borderRadius(2);

    .btnIcon,
    .btnTrailIcon {
      height: pxToRem(16);
      width: pxToRem(16);
    }

    .btnIcon {
      margin-right: spacing(2);
    }

    .btnTrailIcon {
      margin-left: spacing(2);
    }
  }
}

@mixin buttonVariant($color-map) {
  &.va-primary {
    color: map-get($color-map, 'color');
    background-color: map-get($color-map, 'default');

    outline-color: map-get($color-map, 'outline');

    &:hover {
      background-color: map-get($color-map, 'dark');
    }

    &:focus {
      outline: $color-primary solid $outline-width-sm;
      border-color: transparent;
      outline-offset: 4px;
    }

    &:active {
      background-color: $color-monochrome-off-black;
    }
  }

  &.va-secondary {
    color: map-get($color-map, 'default');
    border-width: 1px;
    border-color: map-get($color-map, 'default');
    background-color: $color-monochrome-white;

    outline-color: map-get($color-map, 'outline');

    &:hover {
      color: map-get($color-map, 'dark');
      border-color: map-get($color-map, 'dark');
      outline: $color-primary solid 1px;
      background-color: $color-primary-bg;
    }

    &:focus {
      outline: $color-primary solid $outline-width-sm;
      border-color: $color-primary;
    }

    &:active {
      color: $color-monochrome-off-black;
      border-color: $color-monochrome-off-black;
    }
  }

  &.va-subtle {
    background-color: $color-monochrome-white;
    color: map-get($color-map, 'default');
    border-width: 1px;
    border-color: map-get($color-map, 'subtle-border');

    &:hover {
      color: map-get($color-map, 'dark');
      border-color: map-get($color-map, 'subtle-border-hover');
    }

    &:focus {
      outline: $color-monochrome-label solid $outline-width-sm;
      border-color: transparent;
    }

    &:active {
      color: $color-monochrome-off-black;
      border-color: $color-monochrome-off-black;
      outline: none;
    }
  }

  &.va-text {
    background-color: transparent;
    color: map-get($color-map, 'default');

    &:hover {
      color: map-get($color-map, 'dark');
    }

    &:focus {
      outline: $color-monochrome-input solid $outline-width-sm;
    }

    &:active {
      color: $color-monochrome-off-black;
      outline: none;
    }
  }
}

// color

.co {
  &-primary {
    $color-primary-map: (
      'color': $color-monochrome-off-white,
      'default': $color-primary,
      'dark': $color-primary-dark,
      'outline': $color-primary-light,
      'subtle-border': $color-monochrome-line,
      'subtle-border-hover': $color-monochrome-placeholder,
    );

    @include buttonVariant($color-primary-map);
  }

  &-dark {
    $color-dark-map: (
      'color': $color-monochrome-white,
      'default': $color-monochrome-white,
      'dark': $color-monochrome-white,
      'outline': $color-monochrome-white,
    );

    @include buttonVariant($color-dark-map);

    &.va-secondary {
      background-color: $color-primary-dark;

      &:hover {
        outline: $color-monochrome-white;
        color: $color-monochrome-white;
        background-color: $color-primary-dark;
      }

      &:focus {
        outline: $color-monochrome-white solid 1px;
        border-color: $color-monochrome-white;
      }

      &:active {
        color: $color-monochrome-white;
        border-color: $color-monochrome-white;
      }
    }
  }

  &-error {
    $color-error-map: (
      'color': $color-monochrome-off-white,
      default: $color-error,
      dark: $color-error-dark,
      'outline': $color-error-dark,
    );

    @include buttonVariant($color-error-map);

    &.va-secondary {
      &:focus {
        border-color: transparent;
      }

      &:active {
        border-color: $color-monochrome-off-black;
      }
    }

    &.va-text {
      &:focus {
        outline-color: $color-error-light;
      }
    }
  }

  &-warning {
    $color-warning-map: (
      color: $color-warning-dark,
      default: $color-warning-light,
      dark: $color-warning-light,
      'outline': $color-warning-dark,
    );

    @include buttonVariant($color-warning-map);

    &:hover {
      border: 1px solid $color-warning-dark;
    }

    &:focus {
      outline-color: $color-warning-dark !important;
      border: 1px solid $color-warning-light !important;
      outline-offset: 0 !important;
    }

    &:active {
      background-color: $color-warning-light !important;
    }
  }

  &-default {
    $color-default-map: (
      'color': $color-monochrome-label,
      'default': $color-monochrome-label,
      'dark': $color-monochrome-label,
      'outline': $color-primary-light,
      'subtle-border': $color-monochrome-line,
      'subtle-border-hover': $color-monochrome-placeholder,
    );
    @include buttonVariant($color-default-map);
  }
}

.isLink {
  text-decoration: none;
}

// MODIFIERS

.isIconOnly {
  & .btnIcon {
    margin-right: 0;
  }

  & .btnTrailIcon {
    margin-left: 0;
  }

  &.si {
    &-huge {
      padding: spacing(5);
    }

    &-large {
      padding: spacing(4);
    }

    &-medium {
      padding: spacing(3);
    }

    &-small {
      padding: spacing(3);
    }

    &-x-small {
      padding: spacing(2.5);
    }
  }
}
