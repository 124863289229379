@import '../../../styles/abstracts/index';

.tree-list-holder {
  max-height: pxToRem(312);
  overflow-y: auto;
}

.tree-treenode {
  display: flex;
  align-items: center;

  padding: spacing(4) spacing(3);

  position: relative;

  font-size: pxToRem(14);
  line-height: pxToRem(20);

  &:hover {
    background-color: $color-primary-light;
  }

  // Equivalent to &:focus
  &-active {
    background-color: $color-primary-light;

    .tree-checkbox-custom {
      outline: 2px solid $color-primary;
      outline-offset: 2px;
    }
  }
}

.tree-switcher {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: spacing(1);
  z-index: 1;
  margin-right: spacing(2);

  &_open svg {
    transform: rotate(90deg);
  }

  &-noop {
    // node is a leaf
    z-index: 0;
    opacity: 0;
    visibility: hidden;
  }
}

.tree-checkbox {
  // unchecked checkbox
  .tree-checkbox-background {
    position: absolute;
    // content: '';
    background-color: transparent;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 0;
  }

  // These are left here on purpose (should we need to do some CSS magic later and not waste time on docs)
  // .tree-checkbox-inner {
  //   display: inline-block;
  //   height: 100%;
  //   width: 100%;

  //   background: red;
  //   z-index: 2;
  // }

  // &-indeterminate {
  //   // half-checked checkbox
  //   .tree-checkbox-inner {
  //     background: yellow;
  //   }
  // }
  // &-checked {
  //   // checked checkbox
  //   .tree-checkbox-inner {
  //     background: green;
  //   }
  // }
}

.tree-indent-unit {
  display: inline-block;
  width: 24px;
  height: 10px;
}

.tree-iconEle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.tree-title {
  margin-left: spacing(2);
}
