@import '../../../../../styles/abstracts/index';

.root {
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.info {
  display: flex;
  justify-content: space-between;
}

.dataDisplay {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card {
  position: absolute;
  top: calc(100%);
  display: none;
}

.actions p {
  padding-bottom: spacing(2);
}

.root:hover,
.root:focus {
  div[class*='Badge'] {
    outline: 2pt solid $color-tertiary;
  }

  .card {
    display: flex;
    flex-direction: column;
    width: 250px;
    right: 0;
  }
}

.modalCard {
  max-width: pxToRem(510);
  text-align: center;
}

.modalFooter {
  display: flex;

  & > * {
    flex: 1;
  }
}
