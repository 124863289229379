@import '../../../../../styles/abstracts/index';

.root {
  display: flex;
  flex-direction: column;
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.heading {
  display: flex;
  margin-bottom: spacing(4);
  width: 100%;
}

.header {
  margin-left: spacing(4);
  flex-grow: 1;
}

.edit {
  align-self: start;
  text-decoration: underline;
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 0;
  font-size: pxToRem(14);
}

.editorWrapper {
  margin-bottom: spacing(3);
}

.collapsed {
  max-height: pxToRem(164);
  overflow: hidden;
  position: relative;
}

.collapsed:after {
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) -10%, white 100%);
  bottom: -1px;
  content: "";
  display: block;
  height: pxToRem(48);
  left: 1px;
  right: 1px;
  position: absolute;
}

.collapsedButtonWrapper {
  text-align: center;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  margin-bottom: spacing(4);
}

.fakeLink {
  cursor: pointer;
  font-size: pxToRem(14);
  color: $color-primary !important;
  text-decoration: underline;
  text-underline-offset: 3px;
}

@include respond(tab-land) {
  .root {
    padding: spacing(4);
  }

  .container {
    padding: 0;
  }

  .heading {
    margin-bottom: spacing(3);
  }

  .container {
    padding-bottom: spacing(3);
  }
}
