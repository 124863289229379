@import '../../../../../styles/abstracts/index';

.popover {
  min-width: pxToRem(220);
  max-width: pxToRem(320);

  & > *:not(:last-child) {
    margin-bottom: spacing(2);
  }
}
.btn {
  background: none;
  border: none;
  @include borderRadius(1);
  cursor: pointer;

  height: pxToRem(16);
  width: pxToRem(16);

  margin-left: spacing(2);
  svg {
    height: pxToRem(16);
    width: pxToRem(16);
  }

  &:focus {
    outline: $color-primary-light solid $outline-width-sm;
  }
}

.sheet {
  padding: spacing(4) spacing(6);
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid $color-monochrome-line;
  @include borderRadius(2);
  span {
    margin-bottom: spacing(2);
  }
}

.actions {
  width: 100%;
  display: flex;

  button {
    flex: 1;
  }

  & > button:not(:last-child) {
    margin-right: spacing(2);
  }
}
