@import '../../../../../styles/main.scss';

.root {
  display: flex;
  align-items: center;

  & > *:not(:last-child) {
    margin-right: spacing(2);
  }
}

@include respond(tab-port) {
  .editBtn {
    display: none;
  }

  .moreBtn {
    display: none;
  }

  .favBtn {
    position: fixed;
    bottom: pxToRem(90);
    right: pxToRem(12);
    border-radius: 1000px;
  }

  .sidebarBtn {
    display: inline-flex;
    height: max-content;
  }
}
