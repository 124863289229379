@import '../../../../../styles/abstracts/index';

.root {
  width: 50vw;
  max-width: pxToRem(510);
}

.title {
  display: flex;
  align-items: baseline;
  :first-child {
    margin-right: spacing(2);
  }
}
