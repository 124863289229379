@import '../../../styles/abstracts/index';

.root {
  display: flex;
  gap: spacing(3);
}

.avatar {
  flex-shrink: 0;
}

.userInfo {
  display: flex;
  flex-direction: column;
}

.content {
  display: flex;
  flex-direction: column;
  gap: spacing(2);
}
