@import '../../../../../styles/abstracts/index';

.root {
  display: flex;
  align-items: center;

  padding: spacing(4) spacing(4);
  height: max-content;

  background-color: $color-monochrome-background;
}

.actions {
  margin-left: auto;
}

.logo {
  display: none;
  height: pxToRem(40);
}

.popoverContainer {
  position: relative;
}

.popoverButton {
  border: none;
}

.trigger {
  cursor: pointer;
}

.triggerContainer {
  border-radius: 50%;
  overflow: hidden;

  &:hover,
  &:focus {
    outline: 2px solid $color-primary;
    outline-offset: 1px;
  }
}

.link {
  background: none;
  border: none;
}

@include respond('tab-land') {
  .logo {
    display: inline-block;
  }

  .root {
    padding-left: spacing(6);
    padding-right: spacing(6);
  }
}
