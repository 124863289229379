@import '../../../styles/abstracts/index';

.root {
  position: relative;
}

.input {
  background-color: $color-monochrome-background;
  border-radius: 8px;
  border: 1px solid $color-monochrome-outline;

  display: flex;
  align-items: center;

  transition: background-color $transition-duration-default;

  &:hover {
    background-color: $color-monochrome-white;
  }

  &:focus-within {
    @include inputFocus;
  }
}

.startAdornment {
  &.hasValue {
    margin-right: spacing(2);
  }
}

.inputField {
  flex: 1;
  display: flex;
  align-items: center;

  .label {
    color: $color-monochrome-label;

    transition: font-size $transition-duration-default;
    min-width: fit-content;
  }

  .inputFillable {
    display: flex;
    align-items: center;
    // flex: 1 0 100%;
    width: 100%;
    flex-wrap: wrap;
    gap: spacing(2);
  }

  .searchForm {
    flex: 1;

    opacity: 0;

    &.notSearchable {
      .searchInput {
        visibility: hidden;
      }
    }
  }
  .searchInput {
    width: 100%;

    background: transparent;
    border: none;
    color: $color-monochrome-label;
  }
}

.endAdornment {
  display: flex;
  align-items: center;

  .iconBtn {
    background: transparent;
    border: none;
    cursor: pointer;
    color: $color-monochrome-label;
    @include borderRadius(2);

    &:focus {
      outline: $color-primary-light solid $outline-width-sm;
    }
  }
}

.dropdownHandle {
  &.isOpen {
    transform: rotate(180deg);
  }
}

.clearIcon {
}

.dropdownHandle {
}

// MODIFIERS

.input.sz {
  &-medium {
    .startAdornment {
      margin-left: spacing(6);
    }
    .inputField {
      margin: spacing(4) 0;

      .label {
        font-weight: 400;
        font-size: pxToRem(16);
        line-height: pxToRem(24);
      }

      .searchInput {
        font-weight: 400;
        font-size: pxToRem(16);
        line-height: pxToRem(24);
      }
    }
    .endAdornment {
      margin-right: spacing(6);
    }
  }
  &-large {
    // padding: spacing(3.25) spacing(6);
    .startAdornment {
      margin-left: spacing(6);
    }
    .inputField {
      margin: spacing(5) 0;

      .label {
        font-weight: 400;
        font-size: pxToRem(16);
        line-height: pxToRem(24);
      }

      .searchInput {
        font-weight: 400;
        font-size: pxToRem(16);
        line-height: pxToRem(24);
      }
    }
    .endAdornment {
      margin-right: spacing(6);
    }
  }
}

.input.hasSearchFilled,
.input.isSearchFocused,
.input.hasSelectedItems {
  &.sz {
    &-medium {
      .inputField {
        margin: spacing(1.5) 0;
      }
      .inputField .label {
        font-size: pxToRem(14);
        line-height: pxToRem(20);
      }
    }
    &-large {
      // padding: spacing(2.25) spacing(6);

      .inputField {
        margin: spacing(2.5) 0;
      }
      .inputField .label {
        font-size: pxToRem(14);
        line-height: pxToRem(20);
      }
    }
  }

  .inputField {
    flex-direction: column;
    align-items: flex-start;
  }

  .searchInput {
    min-width: fit-content;
  }

  .searchForm {
    opacity: 1;
  }
}

.dropdownContainer {
  z-index: 100;

  border: 1px solid $color-monochrome-line;

  background: $color-monochrome-white;

  box-shadow: $drop-shadow-medium;
  position: absolute;
  list-style: none;
  overflow: hidden;
  @include borderRadius(2);

  display: none;

  color: $color-monochrome-ash;

  &.isOpen {
    display: flex;
    flex-direction: column;
  }
}

.noDataItem {
  padding: spacing(4);
}

// On error
.root.va-error {
  .input {
    border-color: $color-error;
    background-color: $color-error-light;
    &:focus-within {
      background-color: $color-monochrome-white;
      border-color: $color-error;
      outline: $color-error-dark solid 6px;
      outline-offset: 0px;
    }

    &:hover {
      background-color: $color-monochrome-white;
      border-color: $color-error-dark;
    }
  }
}
