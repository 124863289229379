@import '../../../../../styles/abstracts/index';

.link {
  &:not(:last-child) {
    border-bottom: none !important;
  }
}

.button {
  display: block;
  background-color: $color-monochrome-white;
  border: none;
}
