@import '../../../../../styles/abstracts/index';

@include respond(tab-land) {
  .modal {
    min-width: 100vw;
    width: 100vw;
    max-width: 100vw;

    max-height: 100vh;
    height: calc(100vh - env(safe-area-inset-bottom, 0));
  }
}
