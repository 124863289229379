@import '../../../../../styles/abstracts/index';

.card {
  position: relative;
  min-width: pxToRem(512);
}

.footer {
  display: flex;
  justify-content: flex-end;
}

.group {
  margin-bottom: spacing(6);
}

.textArea {
  min-width: 50vw;
}

.editorMaxHeight {
  & > div > div {
    max-height: 40vh;
    overflow: auto;
  }
}

.closeButton {
  position: absolute;
  top: spacing(4);
  right: spacing(4);
}
