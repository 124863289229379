@import '../../../styles/abstracts/index';

.btn {
  display: none;
}

.btnMobile {
  display: none;
}

.inputWrapper {
  margin-bottom: 8px;
}

.root:focus-within {
  .input {
    min-height: pxToRem(64);
    outline: none;
    border: 2px var(--color-primary) solid;
  }

  .btn {
    display: block;
  }
}

@include respond(tab-port) {
  $box-gap: spacing(2);

  .root {
    display: flex;
    align-items: stretch;

    gap: $box-gap;
  }

  .inputWrapper {
    $btn-width: pxToRem(40);
    flex: 1 0 calc(100% - $btn-width - $box-gap);
    margin-bottom: 0;
  }

  .root:focus-within,
  .active {
    .btn {
      display: none;
    }

    .btnMobile {
      display: block;
    }
  }
}
