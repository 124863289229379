@import '../../../../../styles/abstracts/index';

.root {
  min-height: calc(100vh - 78px);
  display: flex;
  width: 100vw;
}

.main {
  flex: 1;

  display: flex;
  justify-content: center;
  align-items: center;
}

video {
  width: 100%;
}
