@import '../../../../../styles/abstracts/index';

.title {
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  margin-bottom: spacing(8);

  text-align: center;
}

.closeButton {
  position: absolute;
  top: spacing(4);
  right: spacing(4);
}

.avatar {
  margin-bottom: spacing(4);
}

.content {
  display: flex;
  flex-direction: column;

  flex: 1;
}

.actions {
  display: flex;
  margin-top: spacing(4);

  & > * {
    flex: 1;
  }
}

.infoFields {
  display: flex;
  flex-direction: column;
  gap: spacing(2);
  padding-bottom: spacing(10);
}

.topics {
  margin: auto;

  display: flex;
  flex-flow: wrap row;
  justify-content: space-between;
  gap: spacing(2);
  overflow-x: hidden;

  width: 50vw;
  max-width: pxToRem(510);
}

.topic {
  flex: 1 1 32%;

  background-color: $color-monochrome-line;
  display: flex;
  align-items: center;
  padding: spacing(4);

  cursor: pointer;

  @include borderRadius(4);

  &Checked {
    background-color: $color-primary-light;
  }
}

.topicName {
  flex: 1;
  word-wrap: break-word;
  inline-size: min-content;
}

.box {
  margin-right: spacing(2);
}

.interestsBody {
  max-height: 40vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.loading {
  margin: auto;
}

@include respond(tab-land) {
  .page {
    border-radius: 0;

    width: 100vw;
    height: 100vh;
    height: calc(90vh - env(safe-area-inset-bottom, 0));

    display: flex;
    flex-direction: column;

    overflow-y: auto;
  }

  .interestsBody {
    max-height: max-content;
    overflow-y: scroll;
  }

  .topics {
    width: unset;
  }

  .interestsBody,
  .infoFields {
    flex: 1;
  }
}
