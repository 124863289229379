.modal-is-open {
  position: fixed;
  left: 0;
  right: 0;

  // 'bottom' property set in 'body' tag using javascript -> to remember scroll position

  max-height: 100vh;
  max-width: 100vw;
  margin: auto;

  overflow: hidden;
}

.is-text {
  &-center {
    text-align: center;
  }
}

// VISIBILITY

.sr-only:not(:focus):not(:active) {
  // [A11y] Screen reader only
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.is-hidden {
  display: none;
}

.is-required {
  position: relative;

  &::after {
    content: '*';
    position: absolute;
    left: calc(100% + spacing(1));
    color: $color-error;
    font-weight: 500;
  }
}

// RESPONSIVE

.is-only-mobile {
  display: none;
}

.is-only-touch {
  display: none;
}

@include respond(tab-land) {
  .is-only-touch {
    display: inherit;
  }

  .is-not-touch {
    display: none;
  }
}

@include respond(phone) {
  .is-not-mobile {
    display: none;
  }

  .is-only-mobile {
    display: inherit;
  }
}
