@import '../../../styles/abstracts/index';

.root {
  @include borderRadius(2);
  border: 1pt solid $color-monochrome-line;
  box-shadow: $drop-shadow-medium;
}

.header {
  padding: spacing(3) spacing(6) spacing(8);
  padding-top: spacing(6);
  width: 100%;

  & + .body {
    padding-top: 0;
  }
}

.body {
  padding: spacing(6);
  width: 100%;
}

.footer {
  padding: spacing(3) spacing(6);
  padding-bottom: spacing(6);
  width: 100%;
}

// MODIFIERS

.hasNoDropShadow {
  box-shadow: none;
}

.hasTable .body {
  padding: 0;
}

.co {
  &-default {
    background-color: $color-monochrome-white;
  }

  &-primary {
    background-color: $color-primary-light;
  }
}
