@import '../../../../../../styles/abstracts/index';

.root {
}

.input {
  display: flex;
  flex-direction: column;
  gap: spacing(3);
}

.reviewers {
  margin-top: spacing(3);
}

.reviewersContent {
  display: flex;
  justify-content: space-between;
}

.reviewersList {
  display: flex;

  & > :not(:last-child) {
    margin-right: spacing(2);
  }
}

.footer {
  display: flex;
  justify-content: flex-end;

  margin-top: spacing(8);
}
