@import '../../../styles/abstracts/index';

.root {
  height: 100%;
}

.actions {
}

.pages {
  display: none;

  background-color: $color-monochrome-white;
  height: 100%;

  .backBtn {
    position: absolute;
    top: spacing(8);

    transform: translateX(-50%);
  }
}

.page {
  flex: 1;
  min-width: pxToRem(300);
  width: 25vw;
  max-width: pxToRem(350);
  padding: spacing(4) spacing(6) spacing(4);
  height: 100%;
}

// MODIFIERS

.isOpen {
  .actions {
    display: none;
  }

  .pages {
    display: flex;
    flex-direction: column;
    position: relative;
  }
}
