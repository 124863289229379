@import '../../../styles/abstracts/index';

.root {
  position: relative;
}

.handleIcon {
  @include transitionStandard();

  display: inline-flex;
  justify-content: center;
  align-items: center;
  transform: rotate(180deg);

  color: $color-monochrome-label;

  svg {
    height: pxToRem(20);
    width: pxToRem(20);
  }

  &Open {
    transform: rotate(0deg);
  }
}

.button {
  background: none;
  border: none;

  font-size: inherit;

  text-align: left;
  position: absolute;
  width: 60%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: $color-monochrome-ash;
}
