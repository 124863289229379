@import '../../../../../styles/abstracts/index';

.root {
  min-height: 100vh;
  width: 100%;

  background-color: $color-monochrome-white;

  display: grid;
  grid-template-columns: minmax(pxToRem(150), 1fr) pxToRem(824) minmax(
      pxToRem(150),
      1fr
    );
}

.aside {
  width: max-content;

  .closeBtn {
    position: sticky;
    top: spacing(5);
    left: spacing(6);
  }
  .nav {
    position: sticky;
    top: 50vh;
    transform: translateY(-50%);
    padding-right: spacing(4);

    .title {
      margin-left: spacing(6);
      margin-bottom: spacing(3);
    }
  }
}

.main {
  margin-top: spacing(24);

  .header {
    padding-bottom: spacing(8);
  }
  .footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top: spacing(6);
    padding-bottom: spacing(6);
    border-top: 1px solid $color-monochrome-placeholder;
  }
}
