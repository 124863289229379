@import '../../styles/abstracts/index';

.app {
  font-family: $font-family-default;
  font-size: calc($font-size-percent-default * 1%);
}

.content {
  min-height: 100vh;
}
