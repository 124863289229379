@import '../../../styles/abstracts/index';

$height: pxToRem(20);
$width: pxToRem(36);
$switch-width: pxToRem(16);
$toggle-padding: pxToRem(2);

@mixin toggleSizeStyles($height, $width, $switch-size, $toggle-padding) {
  &.root {
    height: $height;
    width: $width;
  }

  .toggle {
    &::after {
      height: $switch-size;
      width: $switch-size;
      left: $toggle-padding;
    }
  }

  .input:checked + .toggle {
    &::after {
      left: calc(100% - $toggle-padding - $switch-size);
    }
  }
}

.root {
  position: relative;
}

.input {
  display: inline-block;
  width: 0;
  height: 0;
  opacity: 0;
  visibility: collapse;
  position: absolute;
}

.input:focus + .toggle {
  outline: $color-monochrome-input solid $outline-width-sm;
}

.toggle {
  display: inline-block;
  height: 100%;
  width: 100%;

  background-color: $color-monochrome-line;
  border-radius: 20000px;

  position: relative;
  cursor: pointer;

  @include transitionStandard();

  &::after {
    content: '';

    border-radius: 20000px;

    background-color: $color-monochrome-off-white;
    box-shadow: $drop-shadow-small;

    position: absolute;
    top: 50%;

    transform: translateY(-50%);
    @include transitionStandard();
  }

  &:hover {
    background-color: $color-monochrome-placeholder;
  }
}

.input:checked + .toggle {
  background-color: $color-primary;
}

.input:checked:focus + .toggle {
  outline: $color-primary-light solid $outline-width-sm;
}

.sz {
  &-small {
    @include toggleSizeStyles(
      pxToRem(20),
      pxToRem(36),
      pxToRem(16),
      pxToRem(2)
    );
  }
  &-medium {
    @include toggleSizeStyles(
      pxToRem(24),
      pxToRem(44),
      pxToRem(20),
      pxToRem(2)
    );
  }
}

.root.isDisabled {
  @include isDisabled;

  .toggle {
    background-color: $color-monochrome-line;
  }
}
