@import '../../../styles/abstracts/index';

.root {
  display: flex;
}

.avatar {
  border: 2px solid $color-monochrome-white;
  margin-left: -1%;
  box-shadow: none;
}

// MODIFIERS

.sz {
  &-small {
    .avatar {
      margin-left: pxToRem(-8);
      font-size: pxToRem(12);

      figure {
        border-radius: 8px;
      }
    }
  }
  &-medium {
    .avatar {
      margin-left: pxToRem(-12);
      font-size: pxToRem(14);

      figure {
        border-radius: 8px;
      }
    }
  }
  &-large {
    .avatar {
      margin-left: pxToRem(-16);
      font-size: pxToRem(16);

      figure {
        border-radius: 8px;
      }
    }
  }
}
