@import '../../../../../styles/abstracts/index';

.root {
  width: 100vw;
}

.main {
  width: 100%;
  max-width: 100vw;

  background-color: $color-monochrome-background;
}

@include respond(tab-port) {
  .main {
    padding-top: 62px;
  }
}
