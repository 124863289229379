*,
*:before,
*:after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;

  outline: none;

  &:focus {
    outline: none;
  }
}

html {
  // This defines what 1rem is
  font-size: calc($font-size-percent-default * 1%); // 100% => 1 rem = 16px;
}

body {
  box-sizing: border-box;
}

a {
  text-decoration: none;

  &:visited,
  &:link {
    color: inherit;
  }
}

// 🖱️ SCROLLBAR
::-webkit-scrollbar {
  width: 16px;
}

::-webkit-scrollbar-track {
  background: $color-monochrome-white;
  @include borderRadius(8);
}

::-webkit-scrollbar-thumb {
  background: $color-gray-200;
  border: 4px solid $color-monochrome-white;

  @include borderRadius(8);
}
