@import '../../../../styles/abstracts/index';

.root {
  display: flex;
  flex-direction: column;

  min-height: 100vh;
}

.mainContainer {
  flex: 1;
  position: relative;
  display: flex;
}

.main {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: $color-monochrome-background;

  gap: 0 pxToRem(24);
}

.drawer {
  // HEADER HEIGHT: 72px
  $header-height: pxToRem(72);

  height: calc(100vh - $header-height);

  position: sticky;
  top: $header-height;
}
