@import '../../../styles/abstracts/index';

.root {
  margin-bottom: spacing(4);
  display: flex;
  justify-content: space-between;
}

.title {
  font-size: pxToRem(20);
  line-height: pxToRem(30);
}

.description {
  color: $color-monochrome-label;
  font-size: pxToRem(16);
  line-height: pxToRem(24);
}

@include respond(tab-land) {
  .title {
    font-size: pxToRem(18);
    line-height: pxToRem(28);
  }

  .root {
    margin-bottom: spacing(3);
  }

  .description {
    font-size: pxToRem(14);
    line-height: pxToRem(20);
  }
}
