@import '../../../styles/abstracts/index';

.root {
  padding: spacing(6) spacing(4);
  box-shadow: $drop-shadow-medium;
  @include borderRadius(1.5);

  display: inline-flex;
  align-items: center;
}

.content {
  font-weight: 500;
}

.icon {
  height: pxToRem(24);
  width: pxToRem(24);
  margin-right: spacing(4);
}

.co {
  &-primary {
    background-color: $color-primary-bg;
    color: $color-primary-dark;
    border: 1px solid $color-primary-light;
    border-left: 4px solid $color-primary;
  }
  &-success {
    background-color: $color-success-bg;
    color: $color-success-dark;
    border: 1px solid $color-success-light;
    border-left: 4px solid $color-success;
  }
  &-warning {
    background-color: $color-warning-bg;
    color: $color-warning-dark;
    border: 1px solid $color-warning-light;
    border-left: 4px solid $color-warning;
  }
  &-error {
    background-color: $color-error-bg;
    color: $color-error-dark;
    border: 1px solid $color-error-light;
    border-left: 4px solid $color-error;
  }
}
