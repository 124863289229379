@import '../../../../../styles/abstracts/index';

.page {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  overflow-y: auto;
  width: 100%;
}

.stepper {
  padding-left: spacing(1);
  padding-right: spacing(1);
}

.preCheck {
  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    margin-bottom: spacing(4);
  }
}

.preCheckTitle {
  display: flex;
  align-items: center;

  svg {
    margin-right: spacing(2);
    height: pxToRem(24);
    width: pxToRem(24);
    color: $color-primary;
  }
}

.missingDocs {
}

.missingDocsHeader {
  display: flex;
  justify-content: space-between;

  .title {
    padding-top: spacing(1);
  }

  .count {
    padding: spacing(1) spacing(2) spacing(1) spacing(2);
    border-radius: $border-radius-base;
    background-color: $color-warning-light;
    color: $color-warning-dark;
    margin-right: spacing(1);
  }
}

.startButton {
  margin-top: spacing(2);
}

.lastApproved {
  margin-top: spacing(2);
}
