@import '../../../../../styles/abstracts/index';

.highlights {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.highlight {
  flex: 1;
  max-width: 50%;

  &:not(:last-child) {
    margin-right: spacing(4);
  }
}

@include respond(tab-land) {
  .highlightSection {
    margin-right: 0;
  }

  .highlights {
    overflow: auto;
  }

  .highlight {
    max-width: 100%;
    margin-bottom: spacing(4);
    flex: 0 0 100vw;
    margin-right: 0;

    &:not(:last-child) {
      margin-right: 0;
    }
  }

  .card {
    padding: spacing(3);
  }
}
