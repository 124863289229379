@import '../../../../../styles/abstracts/index';

.root {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: spacing(4) spacing(6);

  box-shadow: $drop-shadow-small;

  position: sticky;
  top: 0;
  z-index: 1;
  background-color: $color-monochrome-white;
}

.backButton {
}

.actions {
  flex: 1;
}
