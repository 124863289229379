@import '../../../styles/abstracts/index';

.root {
  margin-bottom: spacing(4);
  margin-top: spacing(8);
}

.topWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: spacing(4);
}

.title {
  font-size: pxToRem(36);
  line-height: pxToRem(42);
  margin-bottom: spacing(1);
  font-weight: 600;
}

.description {
  color: $color-monochrome-label;
  font-size: pxToRem(18);
  line-height: pxToRem(28);
}

.divider {
  border: none;
  height: 1px;
  background-color: $color-monochrome-line;
}

@include respond(tab-land) {
  .title {
    font-size: pxToRem(24);
    line-height: pxToRem(32);
  }

  .root {
    margin-bottom: spacing(3);
  }

  .description {
    font-size: pxToRem(16);
    line-height: pxToRem(24);
  }
}
