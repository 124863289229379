@import '../../../../../styles/abstracts/index';

.body {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: pxToRem(510);

  text-align: center;

  & > *:not(:last-child) {
    margin-bottom: spacing(8);
  }
}

.action {
  width: 100%;
}
