@import '../../../../../styles/abstracts/index';

.property {
  margin-bottom: spacing(6);
}

.title {
}

.content {
}

@include respond(tab-port) {
  .content {
    word-wrap: break-word;
  }
}
