@import '../../../styles/abstracts/index';

.root {
  border: 1px solid $color-monochrome-line;
  @include borderRadius(2);
  padding: spacing(4);

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.content {
  display: flex;
  align-items: flex-start;
}

.info {
  margin-left: spacing(4);
}

.actions {
  margin-left: spacing(4);
}

.isRejected {
  border-color: $color-error;
  background-color: $color-error-bg;
  .content {
    align-items: center;
  }
}
