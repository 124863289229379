@import '../../../../../styles/abstracts/index';

.main {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.body {
  display: flex;
  width: pxToRem(510);
  flex-direction: column;
  align-items: center;

  text-align: center;

  & > *:not(:last-child) {
    margin-bottom: spacing(8);
  }
}

.actions {
  align-self: flex-end;

  display: flex;
  align-items: center;
  justify-content: flex-end;
}
