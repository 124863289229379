@import '../../../styles/abstracts/index';

.input {
  outline: none;
  border: none;

  background-color: transparent;
  font-family: $font-family-default;
  font-size: pxToRem(16);
  line-height: pxToRem(24);

  width: 100%;
}
