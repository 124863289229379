@import '../../../../styles/abstracts/index';

.root {
  height: 100vh;
  width: 100vw;

  display: flex;
  align-items: center;
  justify-content: center;
}
