@import '../../../styles/abstracts/index';

.root {
  width: 100%;
  list-style: none;
}

.spaceBottom {
  margin-bottom: spacing(4);
}
