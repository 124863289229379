@import '../../../styles/abstracts/index';

.root {
  padding: spacing(3) spacing(4);
  cursor: pointer;

  font-size: pxToRem(14);
  line-height: pxToRem(20);

  display: flex;
  // justify-content: space-between;
  align-items: center;

  color: $color-monochrome-ash;
  &:hover,
  &:focus {
    color: $color-primary;
    background-color: $color-primary-bg;
  }

  @include transitionStandard();
}

.inputAdornment {
  height: pxToRem(20);
  width: pxToRem(20);

  margin-right: spacing(3);

  align-self: flex-start;

  svg {
    height: 100%;
    width: 100%;
  }
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.text {
}

.description {
  color: $color-monochrome-label;
}

.supportText {
  color: $color-monochrome-label;
}

// MODIFIERS

.isSelectedIcon {
  height: pxToRem(20);
  width: pxToRem(20);

  svg {
    height: 100%;
    width: 100%;
  }
}

.isHighlighted {
  color: $color-primary;
  background-color: $color-primary-bg;
}

.isDisabled {
  @include isDisabled();
}

.isSelected {
  color: $color-primary;
  background-color: $color-primary-bg;

  &.isHighlighted {
    color: $color-primary;
    background-color: $color-primary-bg;
  }

  &.isDisabled {
    color: $color-monochrome-label;
    background-color: $color-monochrome-off-white;
  }
}
