@import '../../../styles/abstracts/index';

.group {
  display: flex;
  align-items: center;

  & > * {
    margin: 0 !important;
  }

  & :global(.is-input-root) {
    position: relative;

    &:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:not(:first-child, :last-child) {
      border-radius: 0;
    }
  }
}
