@import '../../../styles/abstracts/index';

.container {
  position: relative;
}
.isFlex {
  display: flex;
  align-self: stretch;
}

.content {
  padding-left: spacing(4);
  padding-right: spacing(4);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  text-align: center;
  background-color: $color-monochrome-white;
}

.root {
  border: none;
  color: $color-monochrome-line;

  &:not(.isVertical) {
    border-bottom: 1px solid currentColor;

    &.mg {
      &-small {
        margin-top: spacing(4);
        margin-bottom: spacing(4);
      }
      &-medium {
        margin-top: spacing(8);
        margin-bottom: spacing(8);
      }
      &-large {
        margin-top: spacing(16);
        margin-bottom: spacing(16);
      }
    }
  }
}

.isVertical {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: pxToRem(8);
  background-color: currentColor;

  &.mg {
    &-small {
      margin-left: spacing(4);
      margin-right: spacing(4);
    }
    &-medium {
      margin-left: spacing(8);
      margin-right: spacing(8);
    }
    &-large {
      margin-left: spacing(16);
      margin-right: spacing(16);
    }
  }
}
