@import '../../../styles/abstracts/index';

@mixin circleSize($circle-size, $bar-width) {
  $circle-size-half: calc($circle-size / 2);
  $bar-width-half: calc(($bar-width) / 2);
  $inner-circle-width: calc($circle-size - $bar-width);

  &.root {
    width: $circle-size;
    height: $circle-size;
  }

  .mask,
  .fill {
    width: $circle-size;
    height: $circle-size;
  }

  .mask {
    clip: rect(0, $circle-size, $circle-size, $circle-size-half);
  }

  .fill {
    clip: rect(0, $circle-size-half, $circle-size, 0);
  }

  .insideCircle {
    width: $inner-circle-width;
    height: $inner-circle-width;
    margin-top: $bar-width-half;
    margin-left: $bar-width-half;
  }
}

.root {
  background: $color-gray-200;
  border-radius: 50%;
  position: relative;
}

.mask,
.fill {
  position: absolute;
  border-radius: 50%;
}

.fill {
  background-color: $color-primary;
}

.insideCircle {
  background: $color-monochrome-white;

  position: absolute;

  border-radius: 50%;
  z-index: 1;

  display: flex;
  justify-content: center;
  align-items: center;
}

.sz {
  &-x-small {
    @include circleSize(pxToRem(40), pxToRem(4));
  }
  &-small {
    @include circleSize(pxToRem(58), pxToRem(8));
  }
  &-medium {
    @include circleSize(pxToRem(144), pxToRem(16));
  }
  &-large {
    @include circleSize(pxToRem(180), pxToRem(20));
  }
  &-x-large {
    @include circleSize(pxToRem(216), pxToRem(24));
  }
}
