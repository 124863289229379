@import '../../../../../styles/abstracts/index';

.name,
.contentAuthor {
  display: inline-flex;
  align-items: center;
  gap: spacing(3);
}

.name p,
.content p {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  width: fit-content;
}

.root td {
  height: 72px;
}

.root td:nth-of-type(1) {
  max-width: 450px;
}

.row {
  display: none;
}

@include respond(tab-land) {
  .root td,
  .content {
    height: auto;
  }

  .content {
    padding-bottom: spacing(4);
  }

  .content p::before {
    content: open-quote;
  }

  .content p::after {
    content: close-quote;
  }

  .contentAuthor {
    display: none;
  }

  .user {
    display: flex;

    & :first-child {
      margin-right: spacing(2);
    }
  }

  .parent {
    color: $color-monochrome-body;

    em {
      font-style: normal;
      color: $color-primary;
    }
  }

  .comment {
    margin-top: spacing(3);
  }
}
