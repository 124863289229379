@import '../../../styles/abstracts/index';

.root {
  display: flex;
  flex-direction: column;

  & > *:not(:last-child) {
    margin-bottom: spacing(9);
  }
}
.comment {
  flex-basis: 100%;
}

.childComment {
  flex-basis: 80%;
  align-self: flex-end;
}
