@import '../../../styles/abstracts/index';

.root {
  display: flex;
  overflow: hidden;

  position: absolute;
  left: 0;

  min-width: 100%;
  width: max-content;
  max-height: 300px;
  z-index: 10;

  border: 1px solid $color-monochrome-line;

  @include borderRadius(2);

  .menu {
    display: inline-block;
    flex: 1;
    overflow: auto;
  }

  &.menuPosition {
    &-top {
      bottom: calc(100% + pxToRem(8));
    }

    &-bottom {
      top: calc(100% + pxToRem(8));
    }
  }
}

.isClosed {
  display: none;
}
