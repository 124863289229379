@import '../../../styles/abstracts/index';

.root {
  display: inline-flex;
  height: 16px;
  width: 16px;
  border: 1px solid $color-monochrome-outline;

  justify-content: center;
  align-items: center;

  @include borderRadius(1.5);

  svg {
    height: 12px;
    width: 12px;
  }

  position: relative;

  &::after {
    // Prevents "clicking through" the checkbox in case no icon is present

    position: absolute;
    content: '';
    background-color: transparent;

    height: 100%;
    width: 100%;
  }
}

.va {
  &-checked {
    border-color: $color-primary;
    background-color: $color-primary;
    color: $color-monochrome-white;

    &:hover {
      background-color: $color-primary-dark;
    }
  }
  &-half-checked {
    border-color: $color-primary;
    background-color: $color-primary;
    color: $color-monochrome-white;

    &:hover {
      background-color: $color-primary-dark;
    }
  }
  &-unchecked {
    &:hover {
      background-color: $color-primary-light;
      border-color: $color-primary;
    }
    svg {
      opacity: 0;
    }
  }
}
