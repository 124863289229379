@import '../../../../../styles/abstracts/index';

.root {
  padding: spacing(6);
  flex-grow: 1;
}

.body {
  display: flex;
  padding: 0;
  padding-bottom: spacing(4);
}

.body p {
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.col {
  flex: 1;

  &:not(:last-child) {
    margin-right: spacing(6);
  }
}

.footer {
  padding: 0;
  padding-top: spacing(4);

  display: flex;
  justify-content: space-between;
  align-items: center;

  border-top: 1px solid $color-monochrome-line;
}

.author {
  display: flex;
  align-items: center;
}

.authorDescription {
  margin-left: spacing(3);
}

.actions {
  display: flex;
  align-items: center;

  a:not(:last-child) {
    margin-right: spacing(2);
  }
}

@include respond(tab-port) {
  .body {
    display: grid;
    grid-template-areas:
      'row-top row-top'
      'row-mid row-mid'
      'row-low-left row-low-right';
    gap: spacing(5) spacing(4);
    grid-template-columns: 1fr 1fr;
  }

  .col {
    &:nth-child(1) {
      grid-area: row-top;
    }
    &:nth-child(2) {
      grid-area: row-mid;
    }
    &:nth-child(3) {
      grid-area: row-low-left;
      justify-items: stretch;
    }
    &:nth-child(4) {
      grid-area: row-low-right;
      justify-items: stretch;
    }
  }

  .footer {
    flex-direction: column;
    align-items: stretch;
  }

  .actions {
    margin-top: spacing(5);
  }

  .actions > * {
    flex: 1 1 50%;
    word-wrap: normal;
    white-space: nowrap;
  }
}
