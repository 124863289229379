@import '../../../styles/abstracts/index';

.background {
  position: fixed;
  inset: 0;
  width: 100vw;
  height: 100vh;

  background-color: colorOverlay('dark', 0.27);

  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 1000;
}

.modal {
  max-width: 60vw;
  max-height: 80vh;
}
