@import '../../../styles/abstracts/index';

.display {
  &.fw {
    &-regular {
      font-weight: normal;
    }

    &-bold {
      font-weight: 600;
    }
  }

  &.sz {
    &-2x-large {
      font-size: pxToRem(72);
      line-height: pxToRem(90);
      letter-spacing: -0.02em;
    }

    &-x-large {
      font-size: pxToRem(60);
      line-height: pxToRem(72);
      letter-spacing: -0.02em;
    }

    &-large {
      font-size: pxToRem(48);
      line-height: pxToRem(60);
      letter-spacing: -0.02em;
    }

    &-medium {
      font-size: pxToRem(36);
      line-height: pxToRem(44);
      letter-spacing: -0.02em;
    }

    &-small {
      font-size: pxToRem(30);
      line-height: pxToRem(38);
    }

    &-x-small {
      font-size: pxToRem(24);
      line-height: pxToRem(32);
    }
  }
}

.text {
  font-family: 'AvenirNext', 'Roboto', sans-serif;

  &.sz {
    &-x-large {
      font-size: pxToRem(20);
      line-height: pxToRem(30);
    }

    &-large {
      font-size: pxToRem(18);
      line-height: pxToRem(28);
    }

    &-medium {
      font-size: pxToRem(16);
      line-height: pxToRem(24);
    }

    &-small {
      font-size: pxToRem(14);
      line-height: pxToRem(20);
    }

    &-x-small {
      font-size: pxToRem(12);
      line-height: pxToRem(18);
    }
  }

  &.fw {
    &-regular {
      font-weight: normal;
    }

    &-medium {
      font-weight: 500;
    }

    &-bold {
      font-weight: 600;
    }
  }
}

.co {
  &-basic {
    color: inherit;
  }

  &-label {
    color: $color-monochrome-label;
  }

  &-body {
    color: $color-monochrome-body;
  }

  &-primary {
    color: $color-primary;
  }

  &-secondary {
    color: $color-secondary;
  }

  &-tertiary {
    color: $color-tertiary;
  }

  &-accent {
    color: $color-accent;
  }

  &-success {
    color: $color-success;
  }

  &-warning {
    color: $color-warning;
  }

  &-error {
    color: $color-error;
  }
}
