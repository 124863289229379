@import '../../../styles/abstracts/index';

.root {
  position: fixed;

  border-radius: 8px;

  width: max-content;
  max-width: 90vw;
  z-index: 1000;
  background-color: $color-monochrome-white;

  box-shadow: $drop-shadow-medium;

  overflow: hidden;
}
