@import '../../../../../styles/abstracts/index';

.group {
  display: flex;
  align-items: flex-start;
  margin-bottom: spacing(6);

  & > :not(:last-child) {
    margin-right: spacing(3);
  }

  & > * {
    flex: 1;
  }
}

.dueDate {
  flex-wrap: wrap;
}

.dueDate p {
  width: 100%;
  margin-top: spacing(2);
  margin-bottom: spacing(8);
}

.dueDate > div {
  width: 100%;
  flex: auto;
}

@include respond(default) {
  .dueDate > div label {
    width: 100%;
    white-space: break-spaces;
  }

  .dueDate > div > div > span > span {
    display: flex;
  }

  .dueDate > :not(:last-child) {
    margin-right: 0;
  }
}

.label label {
  font-weight: 500;
}

.footer {
  display: flex;
  justify-content: flex-end;
}
