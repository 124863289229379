@import '../../../styles/abstracts/index';

.root {
  background-color: $color-monochrome-background;
  border: 1px solid $color-monochrome-outline;

  display: flex;
  justify-content: space-between;
  align-items: center;

  @include transitionStandard(font-size);

  &:hover {
    background-color: $color-monochrome-white;

    border-color: $color-primary;
  }

  @include borderRadius(2);

  .label,
  .input {
    font-weight: 400;
    font-size: pxToRem(16);
    line-height: pxToRem(24);
  }
}

.sz {
  &-large {
    height: pxToRem(64);
    padding: spacing(5) spacing(6);
  }

  &-medium {
    height: pxToRem(56);
    padding: spacing(4) spacing(6);
  }

  &-small {
    height: pxToRem(48);
    padding: spacing(3) spacing(4);
  }
}

.field {
  flex: 1;

  cursor: text;
  display: flex;
  @include transitionStandard(font-size);
}

.label {
  color: $color-monochrome-label;
  @include transitionStandard(font-size);
  flex: 1;
  pointer-events: none;

  width: 80%;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.input {
  outline: none;
  border: none;

  background-color: transparent;
  flex: 0;
}
.startAdornment {
  margin-right: spacing(2);

  display: flex;
  align-items: center;
  justify-content: center;
  color: $color-monochrome-label;
}

.endAdornment {
  margin-left: spacing(2);

  display: flex;
  align-items: center;

  &Error {
    display: none;
    order: -1;

    color: $color-error-dark;
    height: pxToRem(20);
    width: pxToRem(20);
  }
}

.clearInputAdornment {
  display: none;

  background: transparent;
  border: none;
  cursor: pointer;
  height: pxToRem(24);
  width: pxToRem(24);
  svg {
    height: 100%;
    width: 100%;
  }
}

// MODIFIERS

// STATES

.root:focus-within {
  @include inputFocus;

  .clearInputAdornment {
    display: inline-block;
  }

  &.hasClearInputButton {
    .endAdornmentError {
      margin-right: spacing(2);
    }
  }
}

.root:focus-within,
.isActive {
  &.sz {
    &-large {
      .label {
        font-size: pxToRem(14);
        line-height: pxToRem(20);
      }

      padding-top: spacing(2.5);
      padding-bottom: spacing(2.5);
    }

    &-medium {
      .label {
        font-size: pxToRem(14);
        line-height: pxToRem(20);
      }

      padding-top: spacing(1.5);
      padding-bottom: spacing(1.5);
    }

    &-small {
      .label {
        position: absolute;
        visibility: hidden;
        opacity: 0;
      }
      .field {
        display: block;
      }
    }
  }

  .field {
    justify-content: flex-start;
    flex-direction: column;
    height: 100%;
  }

  .input {
    flex: 1;
  }
}

.isDisabled {
  pointer-events: none;
  opacity: 0.5;
}

.isError {
  &.root {
    background-color: $color-error-light;
    border-color: $color-error;

    &:focus-within {
      background-color: $color-monochrome-white;
      outline: $color-error-dark solid 2px;
    }

    &:hover {
      background-color: $color-monochrome-white;
      border-color: $color-error-dark;
    }
  }

  .endAdornmentError {
    display: inline-block;
  }

  .label,
  .startAdornment {
    color: $color-error-dark;
  }
}
