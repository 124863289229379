@import '../../../../../styles/abstracts/index';

.root {
  width: 26.5vw;
  max-width: pxToRem(510);
}

.body {
  display: flex;
  flex-direction: column;
  align-items: center;

  text-align: center;

  & > h1,
  p {
    margin-bottom: spacing(8);
  }

  button {
    width: 100%;
  }
}
