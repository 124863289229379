@import '../../../../../styles/abstracts/index';

.description {
  padding: spacing(4) spacing(3);
}

.handle {
  margin-left: spacing(2);
}

.handle svg {
  transition: all $transition-duration-default;
}

.handleOpen svg {
  transform: rotate(180deg);
}

.handleOpen {
  outline: 1pt solid $color-primary;
}

.options {
  display: flex;
  flex-direction: column;

  cursor: pointer;
}

.option {
  width: pxToRem(370);
}
