@import '../../../styles/abstracts/index';

.root {
  position: relative;
  display: inline-block;
}

// Height of the arrow = 11.75px
// => side of square (height * 2 / sqrt(2)) ~= 16px
// => rotate 45 deg
$arrow-side: 16px;

.text {
  position: absolute;
  border-radius: 8px;
  width: max-content;
  max-width: pxToRem(330);
  text-align: center;
  z-index: 1;

  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s 0.3s;

  &::after {
    content: '';
    position: absolute;
    display: inline-block;
    height: $arrow-side;
    width: $arrow-side;
  }
}

.sz {
  &-x-small {
    padding: spacing(1) spacing(2);

    &.text::after {
      display: none;
    }
  }

  &-small {
    padding: spacing(2) spacing(3);
  }

  &-medium {
    padding: spacing(4) spacing(6);
  }
}

.co {
  &-light {
    background-color: $color-monochrome-input;
    color: $color-monochrome-body;

    &.text::after {
      background-color: $color-monochrome-input;
    }
  }
  &-dark {
    background-color: $color-monochrome-body;
    color: $color-monochrome-off-white;
    &.text::after {
      background-color: $color-monochrome-body;
    }
  }
}

.po-top {
  bottom: calc(115% + ($arrow-side/2));

  &.text::after {
    bottom: calc(($arrow-side / 2 - 1px) * -1);
  }

  &.ba-start {
    left: -5%;

    &.text::after {
      left: calc(10% + $arrow-side / 2);
      transform: rotate(45deg);
    }
  }
  &.ba-center {
    left: 50%;
    transform: translate(-50%);

    &.text::after {
      left: 50%;
      transform: translate(-50%) rotate(45deg);
    }
  }
  &.ba-end {
    right: -5%;

    &.text::after {
      right: calc(10% + $arrow-side / 2);
      transform: rotate(45deg);
    }
  }
}

.po-bottom {
  top: calc(115% + ($arrow-side/2));

  &.text::after {
    top: calc(($arrow-side / 2 - 1px) * -1);
  }

  &.ba-start {
    left: -5%;

    &.text::after {
      left: calc(10% + $arrow-side / 2);
      transform: rotate(45deg);
    }
  }
  &.ba-center {
    left: 50%;
    transform: translate(-50%);

    &.text::after {
      left: 50%;
      transform: translate(-50%) rotate(45deg);
    }
  }
  &.ba-end {
    right: -5%;

    &.text::after {
      right: calc(10% + $arrow-side / 2);
      transform: rotate(45deg);
    }
  }
}

.po-right {
  left: calc(105% + ($arrow-side));

  &.text::after {
    left: calc(($arrow-side / 2 - 1px) * -1);
  }

  &.ba-start {
    top: -5%;

    &.text::after {
      top: calc(10% + $arrow-side / 2);
      transform: rotate(45deg);
    }
  }
  &.ba-center {
    top: 50%;
    transform: translateY(-50%);

    &.text::after {
      top: 50%;
      transform: translateY(-50%) rotate(45deg);
    }
  }
  &.ba-end {
    bottom: -5%;

    &.text::after {
      bottom: calc(10% + $arrow-side / 2);
      transform: rotate(45deg);
    }
  }
}

.po-left {
  right: calc(105% + ($arrow-side));

  &.text::after {
    right: calc(($arrow-side / 2 - 1px) * -1);
  }

  &.ba-start {
    top: -5%;

    &.text::after {
      top: calc(10% + $arrow-side / 2);
      transform: rotate(45deg);
    }
  }
  &.ba-center {
    top: 50%;
    transform: translateY(-50%);

    &.text::after {
      top: 50%;
      transform: translateY(-50%) rotate(45deg);
    }
  }
  &.ba-end {
    bottom: -5%;

    &.text::after {
      bottom: calc(10% + $arrow-side / 2);
      transform: rotate(45deg);
    }
  }
}

// MODIFIERS

.root:hover .text {
  visibility: visible;
  opacity: 1;
}
