@import '../../../styles/abstracts/index';

@mixin iconSize($size) {
  height: $size + px;
  width: $size + px;
}

.root {
  box-sizing: content-box;
  svg {
    height: 100%;
    width: 100%;
    stroke-width: 1.5;
  }
}

.sz {
  &-x-small {
    @include iconSize(16);
    padding: spacing(1.5);
    border-radius: 4px;
  }
  &-small {
    @include iconSize(20);
    padding: spacing(2);
    border-radius: 4px;
  }
  &-medium {
    @include iconSize(24);
    padding: spacing(2.5);
    border-radius: 8px;
  }
  &-large {
    @include iconSize(36);
    padding: spacing(3);
    border-radius: 8px;
  }
}

.co {
  &-default {
    color: inherit;
  }
  &-primary {
    color: $color-primary;
  }
  &-secondary {
    color: $color-secondary;
  }
  &-tertiary {
    color: $color-tertiary;
  }
  &-accent {
    color: $color-accent;
  }
  &-success {
    color: $color-success;
  }
  &-warning {
    color: $color-warning;
  }
  &-error {
    color: $color-error;
  }
}

.an {
  &-spin {
    animation: spin 0.5s infinite linear;
  }
}

@media (prefers-reduced-motion) {
  .an {
    &-spin {
      animation: spin 5s infinite linear;
    }
  }
}

.hasBackground.co {
  &-default {
    background-color: $color-monochrome-background;
    color: $color-monochrome-ash;
  }
  &-primary {
    background-color: $color-primary-bg;
    color: $color-primary-dark;
  }
  &-secondary {
    background-color: $color-secondary-bg;
    color: $color-secondary-dark;
  }
  &-tertiary {
    background-color: $color-tertiary-bg;
    color: $color-tertiary-dark;
  }
  &-accent {
    background-color: $color-accent-bg;
    color: $color-accent-dark;
  }
  &-success {
    background-color: $color-success-bg;
    color: $color-success-dark;
  }
  &-warning {
    background-color: $color-warning-bg;
    color: $color-warning-dark;
  }
  &-error {
    background-color: $color-error-bg;
    color: $color-error-dark;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
