@import '../../../styles/abstracts/index';

.root {
}

.input {
  display: inline-block;
  height: 0;
  width: 0;
  opacity: 0;
}

.box {
  background-color: $color-monochrome-line;
  display: inline-block;
  cursor: pointer;
  @include borderRadius(10000);

  position: relative;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  @include transitionStandard();

  &::after {
    content: '';
    background-color: $color-monochrome-off-white;

    @include borderRadius(100000);
    @include transitionStandard();

    height: pxToRem(8);
    width: pxToRem(8);

    opacity: 0;
    visibility: hidden;
  }

  &:hover {
    background-color: $color-monochrome-placeholder;
  }
}

.input:focus {
  & + .box {
    outline: $color-monochrome-input solid $outline-width-sm;
  }
}

.input:checked {
  & + .box {
    background-color: $color-primary;

    &::after {
      opacity: 1;
      visibility: visible;
    }

    &:hover {
      background-color: $color-primary-dark;
    }
  }

  &:focus {
    & + .box {
      outline: $color-primary-light solid $outline-width-sm;
    }
  }
}

.sz {
  &-large {
    .box {
      height: pxToRem(24);
      width: pxToRem(24);
    }
  }
  &-medium {
    .box {
      height: pxToRem(20);
      width: pxToRem(20);
    }
  }
  &-small {
    .box {
      height: pxToRem(16);
      width: pxToRem(16);
    }
  }
}

.isDisabled {
  @include isDisabled;
}
