@import '../../../../../styles/abstracts/index';

.body {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.text {
  text-align: left;
}

@include respond(tab-land) {
}

@include respond(tab-port) {
}
