@import '../../../styles/abstracts/index';

.root {
  display: flex;
  gap: spacing(3);
  cursor: pointer;
}

.element {
  align-self: baseline;
}
.label {
  cursor: pointer;
}

.text {
  display: flex;
  flex-direction: column;
  gap: spacing(1);
}

.sz {
  &-small {
    .label {
      font-size: pxToRem(14);
      line-height: pxToRem(20);
    }

    .description {
      color: $color-monochrome-label;
      font-size: pxToRem(14);
      line-height: pxToRem(20);
    }
  }

  &-medium {
    .label {
      font-size: pxToRem(16);
      line-height: pxToRem(20);
    }

    .description {
      color: $color-monochrome-label;
      font-size: pxToRem(16);
      line-height: pxToRem(20);
    }
  }

  &-large {
  }
}

.isDisabled {
  // Element has it's own disabled effects
  // By making the whole element's opacity 0.5 =>
  //  '.element' opacity becomes 1 * 0.5 * 0.5 = 0.25
  .label,
  .description {
    @include isDisabled();
  }
}
