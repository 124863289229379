@import '../../../styles/abstracts/index';

.root {
}

.node {
  color: $color-monochrome-label;
  padding-left: spacing(6);
  position: relative;

  transition: all $transition-duration-default;

  list-style: none;

  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: spacing(3);
  }

  &:focus {
    & > .nodeLabel {
      border-radius: 4px;
      outline: 2px solid $color-primary;
    }
  }
  &:hover {
    padding-left: spacing(6);
    color: $color-primary;

    &::before {
      content: '';
      position: absolute;
      height: 1px;
      width: spacing(3);
      background-color: $color-monochrome-label;
      top: 50%;
      left: 0;
      transform: translateY(50%);
    }
  }

  .nodeLabel {
    color: currentColor;

    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
}

.childNode {
  color: $color-monochrome-label;
  padding-left: spacing(10);
  list-style: none;
  position: relative;
  cursor: pointer;

  transition: all $transition-duration-default;

  &:focus {
    & > .nodeLabel {
      border-radius: 4px;
      outline: 2px solid $color-primary;
    }
  }

  &:hover {
    padding-left: spacing(6);
    color: $color-primary;

    &::before {
      content: '';
      position: absolute;
      height: 1px;
      width: spacing(3);
      background-color: $color-monochrome-label;
      top: 50%;
      left: 0;
      transform: translateY(50%);
    }
  }

  .nodeLabel {
    color: currentColor;

    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
}

.node.active {
  font-weight: 600;
  color: $color-primary;
  padding-left: spacing(16);
  &::before {
    content: '';
    position: absolute;
    height: 2px;
    width: spacing(10);
    background-color: $color-primary;
    top: 50%;
    left: 0;
    transform: translateY(50%);
  }
}

.childNode.active {
  font-weight: 600;
  color: $color-primary;
  padding-left: spacing(16);
  &::before {
    content: '';
    position: absolute;
    height: 2px;
    width: spacing(10);
    background-color: $color-primary;
    top: 50%;
    left: 0;
    transform: translateY(50%);
  }
}

.node.active:has(.childNode.active) {
}
