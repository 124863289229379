@import '../../../styles/abstracts/index';

.container {
  display: flex;
}

.va-primary {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    background: $color-monochrome-line;
    bottom: 0;
    z-index: 0;
  }

  & > *:not(:last-child) {
    margin-right: spacing(4);
  }
}

.va-secondary {
}
