@import '../../../../../styles/abstracts/index';

.root {
  display: flex;
  width: 100%;

  position: relative;
  &::after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    background-color: $color-monochrome-line;
    bottom: 0px;
  }
}

.tabs {
  flex: 1;
  overflow-y: auto;
}
