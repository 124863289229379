@import '../../../../../styles/abstracts/index';

.loading {
}

.title {
  text-align: center;
}

.heroCards {
  display: flex;
  justify-content: space-between;
  gap: 16px;
  margin-top: 0;
  margin-bottom: 16px;

  & > * {
    flex-grow: 1;
  }
}

@include respond(tab-land) {
  .heroCards {
    flex-flow: wrap;

    & > * {
      flex-basis: min-content;
    }
  }
}
