@import '../../../styles/abstracts/index';

.root {
  position: relative;
}

.box {
  display: inline-block;
  cursor: pointer;
  height: 100%;
  width: 100%;
  border: 1pt solid $color-monochrome-outline;

  @include transitionStandard();

  &:hover {
    border-color: $color-primary;
  }
}

.checkIcon {
  color: $color-monochrome-off-white;
  height: 100%;
  width: 100%;
  display: none;
}

.input {
  display: inline-block;
  width: 0;
  height: 0;
  opacity: 0;
  visibility: collapse;
  position: absolute;

  &:focus {
    & + .box {
      outline: $outline-width-sm solid $color-monochrome-input;
    }
  }
}

.input:checked {
  & + .box {
    background-color: $color-primary;
    border-color: $color-primary;

    .checkIcon {
      display: inline-block;
    }

    &:hover {
      background-color: $color-primary-dark;
    }
  }

  &:focus {
    & + .box {
      outline: $outline-width-sm solid $color-primary;
      outline-offset: 2px;
    }
  }
}

// SIZE

.sz {
  &-large {
    width: pxToRem(24);
    height: pxToRem(24);
    .box {
      @include borderRadius(1);
    }
  }

  &-medium {
    width: pxToRem(20);
    height: pxToRem(20);

    .box {
      @include borderRadius(1);
    }
  }

  &-small {
    width: pxToRem(16);
    height: pxToRem(16);
    .box {
      @include borderRadius(1);
    }
  }
}

// MODIFIERS

.isDisabled {
  @include isDisabled;
}
