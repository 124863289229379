@import '../../../../../../styles/abstracts/index';

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  :not(:last-child) {
    margin-bottom: spacing(8);
  }
}

.btn {
  align-self: flex-end;
}
