@import '../../../../../styles/abstracts/index';

.dropdown {
  border: 1px solid $color-monochrome-line;
  @include borderRadius(2);
  overflow: hidden;

  width: pxToRem(240);

  .languageItem {
    width: 100%;
    background: none;
    border: none;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    padding: spacing(2) spacing(4);

    cursor: pointer;

    &:focus,
    &:hover {
      background-color: $color-primary-light;
    }

    .btnIcon {
      margin-right: spacing(2);
    }
  }
}
