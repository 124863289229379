@import '../../../styles/abstracts/index';

.root {
  font-weight: 500;
  @include borderRadius(2);

  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  border: 0;

  &:focus-within {
    outline: $color-primary solid 2px;
  }
}
.root.isRounded {
  border-radius: 10000px;
}

.clickable {
  cursor: pointer;
  &:hover {
    outline: $color-primary solid 1px;
  }
}

.dismissButton {
  cursor: pointer;
  margin-left: pxToRem(8);
  background: none;
  border: none;

  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    height: 100%;
    width: 100%;
  }
}

// SIZE
.sz {
  &-small {
    font-size: pxToRem(14);
    line-height: pxToRem(18);
    padding: 4px 8px;

    .dismissButton {
      height: pxToRem(12);
      width: pxToRem(12);
    }
  }
  &-medium {
    font-size: pxToRem(14);
    line-height: pxToRem(20);
    padding: 6px 12px;

    .dismissButton {
      height: pxToRem(16);
      width: pxToRem(16);
    }
  }
  &-large {
    font-size: pxToRem(14);
    line-height: pxToRem(20);
    padding: 8px 12px;
    .dismissButton {
      height: pxToRem(16);
      width: pxToRem(16);
    }
  }
}

// COLOR
.co {
  &-general {
    background-color: $color-monochrome-input;
    color: $color-monochrome-body;

    .clickable {
      &:focus-within,
      &:hover {
        outline-color: $color-primary;
      }
    }
  }
  &-primary {
    background-color: $color-primary-light;
    color: $color-primary-dark;
    .clickable {
      &:focus-within,
      &:hover {
        outline-color: $color-primary;
      }
    }
  }
  &-secondary {
    background-color: $color-secondary-light;
    color: $color-secondary-dark;
    .clickable {
      &:focus-within,
      &:hover {
        outline-color: $color-secondary;
      }
    }
  }
  &-tertiary {
    background-color: $color-tertiary-light;
    color: $color-tertiary-dark;

    .clickable {
      &:focus-within,
      &:hover {
        outline-color: $color-tertiary;
      }
    }
  }
  &-success {
    background-color: $color-success-light;
    color: $color-success-dark;

    .clickable {
      &:focus-within,
      &:hover {
        outline-color: $color-success;
      }
    }
  }
  &-warning {
    background-color: $color-warning-light;
    color: $color-warning-dark;

    .clickable {
      &:focus-within,
      &:hover {
        outline-color: $color-warning;
      }
    }
  }
  &-error {
    background-color: $color-error-light;
    color: $color-error-dark;
    .clickable {
      &:focus-within,
      &:hover {
        outline-color: $color-error;
      }
    }
  }
}
