@import '../../../../../styles/abstracts/index';

.root {
  display: flex;
  justify-content: space-between;
  box-shadow: $drop-shadow-small;
  position: relative;
  z-index: 10;
  padding: spacing(3) spacing(6);
}

.inverted {
  background-color: $color-primary-dark;
}

.col {
  display: flex;
  align-items: center;

  &:nth-child(2) {
    align-self: center;
  }

  &:last-child {
    justify-self: end;
  }
}

@include respond(tab-port) {
  .root {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    background-color: $color-monochrome-white;
    padding: spacing(2) spacing(6);
  }
}
