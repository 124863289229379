@import '../../../styles/abstracts/index';

.root {
  display: flex;
  align-items: center;
}

.link {
  display: flex;
  align-items: center;
  padding: spacing(1);

  @include borderRadius(1);

  &:hover {
    background-color: $color-primary-bg;
  }
  &:focus {
    outline: 1px solid $color-primary;
  }

  &.inverted {
    &:hover {
      background-color: $color-primary-dark;
    }

    &:focus {
      outline: none;
    }
  }
}

.icon {
  margin-right: spacing(0.5);
}

.icon,
.title {
  color: $color-monochrome-body;

  &.inverted {
    color: $color-monochrome-white;
  }
}

.root:not(:last-child) .title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 340px;
}

.tag {
  margin-left: spacing(1);
}

.current {
  .icon,
  .title {
    color: $color-primary;
    font-weight: 500;
    text-decoration: underline;

    &.inverted {
      color: $color-monochrome-white;
    }
  }
}

.iconOnly {
  .icon {
    margin: 0;
  }
  .title {
    display: none;
  }
}

@include respond(laptop-small) {
  .root:not(:last-child) .title {
    max-width: 120px;
  }
}
