html {
  // 🌈 COLORS

  --color-primary: #593da6;
  --color-primary-bg: #f3efff;
  --color-primary-dark: #402c77;
  --color-primary-light: #e3daff;

  --color-secondary: #33a1ab;
  --color-secondary-dark: #26767e;
  --color-secondary-light: #d0eef1;
  --color-secondary-bg: #eff9fa;

  --color-tertiary: #5075f1;
  --color-tertiary-bg: #f2f5ff;
  --color-tertiary-dark: #032596;
  --color-tertiary-light: #e5ebfe;

  --color-accent: #f759b8;
  --color-accent-bg: #feecf7;
  --color-accent-shade-1: #fa87cc;
  --color-accent-shade-2: #ffb5e1;
  --color-accent-dark: #9c0760;

  --color-success: #006f00;
  --color-success-dark: #004600;
  --color-success-light: #b8dfb8;
  --color-success-bg: #e2f2e2;

  --color-warning: #ff9439;
  --color-warning-light: #ffd5b2;
  --color-warning-bg: #ffe6d1;
  --color-warning-dark: #562800;

  --color-error: #bc0848;
  --color-error-bg: #fff0f3;
  --color-error-dark: #72182b;
  --color-error-light: #f5c9d1;

  --color-rgb-overlay-dark: 20, 20, 43;
  --color-rgb-overlay-light: 239, 240, 246;

  --color-monochrome-ash: #222223;
  --color-monochrome-background: #f1f1fa;
  --color-monochrome-body: #4e4b66;
  --color-monochrome-input: #eff0f6;
  --color-monochrome-label: #5d5f7a;
  --color-monochrome-line: #d9dbe9;
  --color-monochrome-off-black: #14142b;
  --color-monochrome-off-white: #fcfcfc;
  --color-monochrome-placeholder: #a0a3bd;
  --color-monochrome-white: #ffffff;
  --color-monochrome-black: #000000;
  --color-monochrome-outline: #8a91b6;

  --color-gray-200: #e4e7ec;
}
