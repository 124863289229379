@font-face {
  font-family: 'AvenirNext';
  src: local('Avenir Next'), local('AvenirNext'),
    url('../assets/fonts/avenir_next/AvenirNext-Regular-08-subset.woff')
      format('woff');
  font-weight: normal;
}

@font-face {
  font-family: 'AvenirNext';
  src: local('AvenirNextMedium'),
    url('../assets/fonts/avenir_next/AvenirNext-Medium-06-subset.woff')
      format('woff');
  font-weight: 500;
}

@font-face {
  font-family: 'AvenirNext';
  src: local('AvenirNextDemi'),
    url('../assets/fonts/avenir_next/AvenirNext-DemiBold-03-subset.woff')
      format('woff'),
    local('AvenirNextDemi'),
    url('../assets/fonts/avenir_next/AvenirNext-DemiBold-03-subset.woff')
      format('woff');
  font-weight: 600;
}

@import './abstracts/index';
@import './base/index';
@import './theme/index';
