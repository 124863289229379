@import '../../../../../styles/abstracts/index';

.root {
  flex: 1;
  display: flex;
  align-items: center;

  & > :not(:last-child) {
    margin-right: 12px;
  }
}

.nav {
  flex: 1;
}

.rootMobile {
  display: none;
}

@include respond(tab-port) {
  .nav {
    display: none;
  }

  .mobileHidden {
    display: none;
  }

  .rootMobile {
    display: flex;
    width: 100%;
    align-items: center;
  }

  .sidebarBtn {
    margin-left: auto;
  }

  .favBtn {
    position: fixed;
    bottom: 32px;
    right: 16px;
    border-radius: 1000px;
  }
}
