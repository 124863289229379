@import '../../../../../styles/abstracts/index';

.property {
  margin-bottom: spacing(6);
}

.list {
  list-style: none;

  a {
    color: $color-primary !important;
    display: flex;
    gap: spacing(1);
    align-items: center;
  }
}

@include respond(tab-port) {
  .content {
    word-wrap: break-word;
  }
}
