@import '../../../../../styles/abstracts/index';

.root {
  padding: spacing(6);
  width: 100%;
  max-width: spacing(72);
  display: flex;
  flex-wrap: wrap;
  gap: spacing(6);
  align-content: flex-start;
}

.section {
  width: 100%;
}

.left {
  float: left;
}

.fakeLink {
  color: $color-primary !important;
  text-decoration: underline;
  text-underline-offset: 3px;
  cursor: pointer;
}

.roundIcon {
  @include borderRadius(10000);
}

@include respond(tab-port) {
  .root {
    margin-top: spacing(6);
    margin-left: 0;
    flex-wrap: nowrap;
    width: auto;
  }
}

@include respond(default) {
  .root {
    max-width: 100%;
  }

  .section {
    width: auto;
  }
}
