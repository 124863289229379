@import '../../../../../styles/abstracts/index';

.description {
  padding: spacing(4) spacing(3);
}

.handle {
  transition: all $transition-duration-default;
}

.badge {
  margin-left: spacing(2);
}

.handleOpen {
  transform: rotate(180deg);
}

.options {
  display: flex;
  flex-direction: column;

  cursor: pointer;
}

.option {
  background: transparent;
  border: none;
}
