@import '../../../styles/abstracts/index';

.root {
  @include transitionStandard();

  height: 100%;
  width: 100%;
}

.co {
  &-standard {
    fill: $color-primary;
  }

  &-black {
    fill: $color-monochrome-black;
  }

  &-negative {
    fill: $color-monochrome-white;
    background-color: $color-monochrome-ash;
  }
}
