@import '../../../styles/abstracts/index';

.root {
  background: none;
  border: none;
  display: flex;
  align-items: center;
  padding: spacing(4);
  min-width: pxToRem(300);

  cursor: pointer;

  &:not(:last-child) {
    border-bottom: 1px solid $color-monochrome-line;
  }

  &:focus,
  &:hover {
    background-color: $color-primary-light;
  }
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.content {
  flex: 1;
  text-align: left;
}
