@import '../../../../../styles/abstracts/index';

.root {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.main {
  margin: auto;

  width: 25%; // CHECKME: a bit hacky - assumes the length of content - styles need to be fixed at Select component level
}

.actions {
  display: flex;
  align-items: center;

  & > :not(:last-child) {
    margin-right: 12px;
  }
}
