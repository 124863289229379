@function stripUnit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}

// @function colorOverlay
//    color: 'light' | 'dark'
//    transparency: 0.00 - 1.00
@function colorOverlay($color, $transparency: 1) {
  $overlay-color: map-get($color-overlay, $color);
  @return rgba($overlay-color, $transparency);
}

@function spacing($size: 1) {
  $base-value: stripUnit($spacer-base-value);
  @return ($base-value * $size * 1px);
}

// @function pxToRem
// pxValue: number
// @example pxToRem(16) => 1rem
@function pxToRem($pxValue) {
  $remSize: calc($pxValue / (16 * $font-size-percent-default / 100));
  @return #{$remSize}rem;
}

// MEDIA QUERY MANAGER
/*
@mixin respond

  0 - 375px  Phone
  376 - 696px Tablet (portrait)
  697 - 834px Tablet (landscape)
  [835 - 1440px] This is where our normal styles apply
  834px - 1024px Laptop small
  1441px+  Desktop

  $breakpoint argument choices:
  - phone
  - tab-port
  - tab-land
  - laptop-small
  - desktop

  1em=16px
*/

@mixin respond($breakpoint) {
  @if $breakpoint==phone {
    @media screen and (max-width: 23.4375em) {
      // 375px
      @content;
    }
  }

  @if $breakpoint==tab-port {
    @media screen and (max-width: 43.5em) {
      // 696px
      @content;
    }
  }

  @if $breakpoint==tab-land {
    @media screen and (max-width: 52.125rem) {
      // 834px
      @content;
    }
  }

  @if $breakpoint==laptop-small {
    @media screen and (max-width: 64rem) {
      // 1024px
      @content;
    }
  }

  @if $breakpoint==default {
    @media screen and (min-width: 835px) and (max-width: 1440px) {
      // 835-1440px
      @content;
    }
  }

  @if $breakpoint==desktop {
    @media screen and (min-width: 90.0625rem) {
      // 1441px+
      @content;
    }
  }
}

@mixin borderRadius($multiplier: 1) {
  border-radius: calc($border-radius-base * $multiplier);
}

@mixin transitionStandard($property: all) {
  transition: $property $transition-duration-default;
}

@mixin isDisabled {
  opacity: 0.5;
  pointer-events: none;
}

@mixin inputFocus {
  background-color: $color-monochrome-white;
  outline: $outline-width-sm $color-primary solid;
}
