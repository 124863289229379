@import '../../../../../styles/abstracts/index';

.root {
  display: flex;
  align-items: center;
  gap: spacing(2);
}

.sidebarBtn {
  display: none;
}

.favBtn {
  border-width: 1px;
}

@include respond(tab-port) {
  .editBtn {
    display: none;
  }

  .moreBtn {
    display: none;
  }

  .favBtn {
    position: fixed;
    bottom: 32px;
    right: 16px;
    border-radius: 1000px;
  }

  .sidebarBtn {
    display: inline-flex;
    height: max-content;
  }
}
