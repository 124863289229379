@import '../../../../../styles/abstracts/index';

.root {
  min-width: 900px;
  width: 50vw;
  max-width: 1000px;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.title {
  margin-bottom: spacing(6);
}

.content {
}

.document {
  border: 1px solid $color-monochrome-line;
  padding: spacing(4);
  display: flex;
  align-items: center;
  border-radius: 8px;
}

.documentContent {
  margin-left: spacing(2);
}

.uploadInput {
  margin-top: spacing(3);
}
.uploadProgress {
  display: flex;
  flex-direction: column;
}

.exchangeIcon {
  margin: spacing(4) auto;
  transform: rotate(90deg);
}

.footer {
  display: flex;
  justify-content: flex-end;

  button:not(:last-child) {
    margin-right: spacing(2);
  }
}
