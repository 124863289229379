@import '../../../styles/abstracts/index';

.root {
  & > div {
    font-family: 'AvenirNext', 'Roboto', sans-serif;
    overflow: inherit !important;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    ul,
    ol {
      font-size: 1rem !important;
      margin-bottom: spacing(2);
    }

    ul,
    ol {
      padding-left: 0;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    strong {
      font-weight: 500;
    }
  }
}

.readOnly {
  & > div {
    border: none !important;
    & > div {
      padding: 0;
    }
  }
}

.hidden {
  display: none;
  opacity: 0;
}
