@import '../../../styles/abstracts/index';

.root {
  background-color: $color-primary-bg;
  color: $color-primary;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  position: relative;

  outline: none;

  &:focus {
    outline: $outline-width-sm solid $color-primary-light;
  }
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;

  svg {
    stroke-width: 1.5;
  }
}

.badge {
  position: absolute;

  border: 1px solid $color-monochrome-off-white;
  background-color: $color-monochrome-off-white;
  border-radius: 10000px;
  overflow: hidden;

  z-index: 1;

  display: flex;
  justify-content: center;
  align-items: center;

  & > * {
    height: 100%;
    width: 100%;
  }

  &-top-left {
    top: 0;
    left: 0;
  }

  &-top-right {
    top: 0;
    right: 0;
  }

  &-bottom-left {
    bottom: 0;
    left: 0;
  }

  &-bottom-right {
    bottom: 0;
    right: 0;
  }
}

.imageContainer {
  height: 100%;
  width: 100%;

  border-radius: 10000px;
  overflow: hidden;
}

.image {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

@mixin setSize($size) {
  height: $size + px;
  width: $size + px;
}

.sz {
  &-x-small {
    @include setSize(24);
    border-radius: 4px;

    .badge {
      @include setSize(6);
    }

    .initials {
      font-size: pxToRem(12);
      line-height: pxToRem(18);
    }

    svg {
      @include setSize(16);
    }
  }

  &-small {
    @include setSize(32);
    border-radius: 8px;

    .badge {
      @include setSize(8);
    }

    .initials {
      font-size: pxToRem(14);
      line-height: pxToRem(20);
    }

    svg {
      @include setSize(20);
    }
  }

  &-medium {
    @include setSize(40);
    border-radius: 8px;

    .badge {
      @include setSize(10);
    }

    .initials {
      font-size: pxToRem(16);
      line-height: pxToRem(24);
    }

    svg {
      @include setSize(24);
    }
  }

  &-large {
    @include setSize(48);
    border-radius: 8px;

    .badge {
      @include setSize(12);
    }

    .initials {
      font-size: pxToRem(18);
      line-height: pxToRem(28);
    }

    svg {
      @include setSize(24);
    }
  }

  &-x-large {
    @include setSize(56);
    border-radius: 12px;

    .badge {
      @include setSize(14);
    }

    .initials {
      font-size: pxToRem(20);
      line-height: pxToRem(30);
    }

    svg {
      @include setSize(28);
    }
  }

  &-2x-large {
    @include setSize(64);
    border-radius: 12px;

    .badge {
      @include setSize(16);
    }

    .initials {
      font-size: pxToRem(24);
      line-height: pxToRem(32);
    }

    svg {
      @include setSize(28);
    }
  }
}
