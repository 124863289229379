@import '../../../../../styles/abstracts/index';

@mixin highlight {
  color: $color-primary-dark;
  background-color: $color-primary-light;
}

.root {
  &:hover .wrapper {
    @include highlight;
  }

  &:focus .wrapper {
    outline: $outline-width-sm solid $color-primary;
  }
}

.wrapper {
  padding: spacing(2);

  @include borderRadius(2);

  display: flex;
  align-content: center;

  text-decoration: none;
  color: inherit;

  color: $color-monochrome-label;
}

.icon {
  height: pxToRem(24);
  width: pxToRem(24);

  svg {
    height: 100%;
    width: 100%;
  }
}

.text {
  font-size: pxToRem(16);
  line-height: pxToRem(24);
  font-weight: 500;
  margin-left: spacing(3);
}

.isCollapsed {
  justify-content: center;
}

.isSelected .wrapper {
  @include highlight;

  position: relative;

  &::before {
    content: '';
    position: absolute;
    background-color: $color-primary;
    border-radius: 0 6px 6px 0;
    width: 6px;
    height: 100%;
    left: -16px;
    top: 0;
  }
}

// MEDIA QUERIES

@include respond(tab-land) {
  .root {
    padding: spacing(3) spacing(4);
  }

  .wrapper {
    padding: spacing(3);
    display: block;
  }

  .isSelected .wrapper {
    &::before {
      content: '';
      position: absolute;
      background-color: $color-primary;
      border-radius: 6px 6px 0px 0;
      height: 6px;
      width: 100%;
      bottom: -12px;
      top: unset;
      left: 0;
    }
  }

  .icon {
    display: block;
  }

  .hideOnMobile {
    display: none;
  }
}
