@import '../../../../../styles/abstracts/index';

.root {
  width: 50%;
  cursor: pointer;
  position: relative;
}

.root:hover,
.root:focus {
  border-color: $color-primary;

  .fakeLink {
    text-decoration-thickness: 2px;
  }
}

.wrapper {
  display: flex;
  flex-direction: row;
  gap: spacing(6);
}

.fakeLink {
  color: $color-primary !important;
  text-decoration: underline;
  text-underline-offset: 3px;
}

// Make the whole card clickable.
.link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: '';
}

.text {
  display: flex;
  flex-direction: column;
  gap: spacing(1);
  margin-bottom: spacing(2);
}

.textContainer {
  flex-grow: 1;
}

.description {
  margin-left: spacing(4);
}

.clickable {
  position: relative;
  z-index: 2;
}

.mainIcon {
  background-color: $color-primary-bg;
  color: $color-primary;
  height: 80px;
  width: 80px;
  border-radius: 12px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  &:focus {
    outline: $outline-width-sm solid $color-primary-light;
  }
}

@include respond(tab-land) {
  .root {
    width: 100%;
  }

  .mainIcon {
    height: 40px;
    width: 40px;
    border-radius: 8px;
    padding: 8px;
  }

  .wrapper {
    gap: spacing(4);
  }

  .clickable {
    display: none;
  }
}
