@import '../../../../../styles/abstracts/index';

.document {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-top: spacing(2);
  padding: spacing(2);

  background-color: $color-monochrome-white;
  border: solid 1px $color-monochrome-line;
  @include borderRadius(2);
}
