@import '../../../../../styles/abstracts/index';

.root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.pageActions {
  display: flex;
  justify-content: flex-end;
  margin-bottom: spacing(4);
  flex-basis: pxToRem(42);

  :not(:first-child) {
    margin-left: spacing(2);
  }
}
